import styled, { keyframes } from 'styled-components/macro';

interface IContainerProps {
  type?: 'error' | 'success';
  active: boolean;
  menuIsOpen: boolean;
}

const slideInAnimation = keyframes`
  0%
  {
    transform: translate(0px, 0px);
  }
  70%
  {
    transform: translate(467px, 0px);
  }
  80%
  {
    transform: translate(457px, 0px);
  }
  100%
  {
    transform: translate(462px, 0px); 
  }
`;

const slideOutAnimation = keyframes`
  0%
  {
    transform: translate(462px, 0px);
  }
  100%
  {
    transform: translate(0px, 0px); 
  }
`;

const slideInAnimationWithMenu = keyframes`
  0%
  {
    transform: translate(0px, 0px);
  }
  70%
  {
    transform: translate(617px, 0px);
  }
  80%
  {
    transform: translate(607px, 0px);
  }
  100%
  {
    transform: translate(612px, 0px); 
  }
`;

const slideOutAnimationWithMenu = keyframes`
  0%
  {
    transform: translate(612px, 0px);
  }
  100%
  {
    transform: translate(0px, 0px); 
  }
`;
const noAnimationWithMenu = keyframes`
  0%
  {
    transform: translate(0px, 0px);
  }
  100%
  {
    transform: translate(0px, 0px); 
  }
`;

export const Container = styled.div<IContainerProps>`
  animation: ${({ active, menuIsOpen }) => {
      return active
        ? menuIsOpen
          ? slideInAnimationWithMenu
          : slideInAnimation
        : menuIsOpen
        ? slideOutAnimationWithMenu
        : active && menuIsOpen
        ? slideOutAnimation
        : noAnimationWithMenu;
    }}
    1s forwards;
  position: fixed;
  bottom: 10px;
  width: 400px;
  left: -400px;
  z-index: ${({ theme }) => theme.zIndex.top};
`;
