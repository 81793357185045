import styled from 'styled-components/macro';

export const Container = styled.div`
  cursor: pointer;

  width: auto; // Reset width
  height: auto; // Reset height

  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .react-player > div {
    position: absolute; // Scaling will occur since parent is relative now
  }
`;
