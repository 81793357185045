import { AccountType, AddOnApp } from '../../generated/graphql';
import { convertCostCleanly } from './numbers';

export interface IPlanOptionProps {
  id: string;
  type: AccountType;
  addOnApp?: AddOnApp;
  heading: string;
  subHeading: string;
  text: string;
  price: string;
  priceDescription: string;
  monthlyPrice: string;
  monthlyPriceDescription: string;
  billedAnnually: boolean;
  savingDescription?: string;
  CTA: string;
  features: string[];
}

export const plans = {
  freeStarter: {
    id: 'freeStarter',
    type: AccountType.Registered,
    heading: 'Free Forever',
    subHeading: 'Ideal to manage all your recipes in one place',
    text: 'Ideal to manage all your recipes in one place',
    price: 'Free',
    priceDescription: 'for personal use',
    monthlyPrice: 'Free',
    monthlyPriceDescription: 'for personal use',
    billedAnnually: false,
    CTA: 'Start Free',
    features: [
      'Unlimited Recipes',
      'Unlimited Ingredients',
      '1 Personal Venue',
      '3 Recipe Versions',
      'Share Recipes',
    ],
  },
  headChef: {
    type: AccountType.HeadChef,
    id: 'headChef',
    heading: 'Recipe +',
    subHeading: 'Save time & maximise your recipe mangement to improve flavour',
    text:
      'Upgrade recipe management with Images, Recipe Templates & Stocktaking',
    price: '6',
    priceDescription: 'per month, per venue',
    billedAnnually: true,
    monthlyPrice: '10',
    monthlyPriceDescription: 'per month, per venue',
    CTA: 'Start Now',
    savingDescription: 'Save 40%',
    features: [
      'Unlimited Venues',
      'Sub Recipes',
      'Scale Recipes',
      'Add Recipe Images',
      'Unlimited Menus',
      'Automated Ordering',
      'Stocktake',
      'Inventory',
    ],
  },
  startUp: {
    type: AccountType.BusinessStarter,
    addOnApp: AddOnApp.Calculator,
    addOnPromoHeading: 'Add On Account*',
    addOnPromoText:
      'Add Calculations to your recipes when it suits you on all accounts',
    id: 'startUp',
    heading: 'Recipe Revenue',
    subHeading: 'Calculate your recipe costs & compare profitability',
    text: 'Calculate your recipe costs & compare profitability',
    price: '100',
    priceDescription: '30 days of calculations',
    billedAnnually: true,
    monthlyPrice: '160',
    monthlyPriceDescription: '20 days of calculations',
    CTA: 'Calculate Recipes',
    savingDescription: 'Save 82%',
    features: ['Calculate Recipes', 'Menu Costings', 'Yeild Targets'],
  },
  scaleOut: {
    type: AccountType.BusinessOwner,
    id: 'scaleOut',
    heading: 'Scale Up',
    subHeading: 'Compare profitability & recipe costs all year',
    text: 'Compare profitability & recipe costs all year',
    price: '300',
    priceDescription: '365 days of calclations',
    billedAnnually: true,
    monthlyPrice: '38',
    monthlyPriceDescription: '365 days of calclations',
    CTA: 'Calculate Recipes',
    savingDescription: 'Save 41%',
    features: ['Calculate Recipes', 'Menu Costings', 'Yeild Targets'],
  },
};

export const plansArray: IPlanOptionProps[] = [
  plans.freeStarter,
  plans.headChef,
  plans.startUp,
  plans.scaleOut,
];

export const getPlan = (type: AccountType) => {
  return plansArray.find((t) => t.type === type);
};

export const stringsMatch = (s1?: string, s2?: string) => {
  return s1 === s2;
};

export const capitalizeFirstLetter = (
  maybeString: string | undefined,
  underscoreCheck?: boolean
) => {
  const string = maybeString || '';
  if (underscoreCheck) {
    return string
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : string;
};

export const capitalizeFirstLetterPerWord = (
  string?: string,
  underscoreCheck?: boolean,
  returnEmptyString?: boolean
) => {
  if (!string) {
    return returnEmptyString ? '' : '-';
  }
  if (underscoreCheck) {
    return string
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const toTitleCase = (string: string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const cleanURL = (url: string) => {
  const formattedString = url
    .replace('https://', '')
    .replace('http://', '')
    .replace('www.', '');

  // Find the index of the first dot
  let dotIndex = formattedString.indexOf('.');
  if (dotIndex === -1) {
    return url; // Return the original url if no dot is found
  }

  // Find the index of the first slash after the first dot
  let slashIndex = formattedString.indexOf('/', dotIndex);
  if (slashIndex === -1) {
    return url; // Return the original url if no slash is found after the dot
  }

  // Return the substring from the start to the slash
  const formattedURL = formattedString.substring(0, slashIndex);

  if (formattedURL.endsWith('/')) {
    // Remove the slash at the end
    const reFormattedURL = formattedURL.slice(0, -1);
    return `www.${reFormattedURL}`;
  }
  return `www.${formattedURL}`;
};

export const findMatchingStrings = (originalName: string, url: string) => {
  // Convert both strings to lowercase to ensure case-insensitive comparison
  const name = originalName.toLowerCase();
  url = url.toLowerCase();

  // Split each string into words using a regex that considers non-alphanumeric characters as delimiters
  let nameWords = name.split(/[^a-z0-9]+/);
  const urlWords = url.split(/[^a-z0-9]+/);

  // Push the concatenated version of the name (without spaces or special characters) to the nameWords array
  nameWords.push(name.replace(/[^a-z0-9]+/g, ''));

  // Use filter to find common words, avoiding duplicates by ensuring each word is checked only once
  const commonWords = nameWords.filter((word, index, self) => {
    return urlWords.indexOf(word) !== -1 && self.indexOf(word) === index;
  });

  // If the concatenated name is part of the common words, return the originalName instead of the modified one
  const resultIndex = commonWords.indexOf(name.replace(/[^a-z0-9]+/g, ''));
  if (resultIndex !== -1) {
    commonWords[resultIndex] = originalName; // Replace the modified name with the original format
  }

  // Join the common words to form the matching string result
  return commonWords.join(' '); // or use any other separator as needed
};

export const getMonthlyCost = (
  profit: number,
  weeklyServes: number,
  isBusinessAccount: boolean
) => {
  const projectedProfit = profit * weeklyServes * 52 * 1;

  if (isBusinessAccount) {
    if (projectedProfit < 5000) return convertCostCleanly(plans.startUp.price);
    return convertCostCleanly(plans.scaleOut.price);
  }

  if (projectedProfit < 1000) return `$${plans.freeStarter.price}`;

  return convertCostCleanly(plans.headChef.price);
};

export const usage = [
  {
    label: 'Unlimited Recipes',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info: 'Describes the total recipes you can have in your account.',
  },
  {
    label: 'Recipe Templates',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'Recipe Termplates are pre costed recipes that you can click on to create a new recipe in seconds.',
  },
  {
    label: 'Ingredient Database Access',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info: `All our ingredients are crowd sourced (All ingredient data is shared across all users anonymously), meaning that you don't have to enter in all your ingredient costs before starting.`,
  },
  {
    label: 'Venues',
    freeStarter: '1',
    headChef: 'Unlimited',
    startUp: 'Unlimited',
    scaleOut: 'Unlimited',
    info:
      'All recipes are connected to your Venue or Personal Venue. This enables you forcast profitability, increase privacy or share your recipes with venues when working with them & remove them when you move on.',
  },
  // {
  //   label: 'Share Recipes',
  //   freeStarter: 'Coming Soon',
  //   headChef: 'Coming Soon',
  //   startUp: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info: 'Share your recipes privately & securely',
  // },
  // {
  //   label: 'Free Trial Recipes',
  //   freeStarter: true,
  //   headChef: true,
  //   startUp: true,
  //   scaleOut: true,
  //   info:
  //     'Try out all our features for free before selecting the correct plan for you. You can change your plan at any time in your account settings.',
  // },
  // {
  //   label: 'Personal Venue',
  //   freeStarter: '1',
  //   headChef: '1',
  //   startUp: '10',
  //   scaleOut: '10',
  //   info:
  //     'A Personal Venue enables you to tinker away at recipes privately factoring in venue, staff & food costs.',
  // },
  // {
  //   label: 'Business Venue',
  //   freeStarter: false,
  //   headChef: false,
  //   startUp: false,
  //   scaleOut: '1',
  //   info:
  //     'A Business Venue enables staff to colaborate on recipes, integrate POS & access advances analytics to grow your business more profitably.',
  // },
  // {
  //   label: 'Additional Staff Access',
  //   freeStarter: false,
  //   headChef: false,
  //   startUp: false,
  //   scaleOut: '3',
  //   info:
  //     'Add staff to your business venue to increase creativity & focus across the team.',
  // },
];

export const essentials = [
  {
    label: 'Recipe Versions',
    freeStarter: '3 per recipe',
    headChef: 'Unlimited',
    startUp: 'Unlimited',
    scaleOut: 'Unlimited',
    info:
      'Create a recipe, then duplicate it to create a new recipe version. Improve the recipe, now calculate/ save your new recipe version to see the differences your chnages have on profit.',
  },
  {
    label: 'Add Ingredients',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info: 'Add any ingredient you need to use freely.',
  },
  {
    label: 'Edit Ingredients',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'Ensure your recipe calculations are accurate, edit any ingredient you need to.',
  },
  {
    label: 'Sub Recipes',
    freeStarter: false,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'We all make recipes that can be added to other recipes, sauces, species you get the point. We have enabled any recipe to be added to another recipe.',
  },
  {
    label: 'Scale Recipes',
    freeStarter: false,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'Scale your recipe based on recipe serves. (In real time scale a 4 serves recipe to 14 serves)',
  },
  // {
  //   label: 'Recipe Images',
  //   freeStarter: false,
  //   startUp: 'Coming Soon',
  //   headChef: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info:
  //     'Add images to sell your recipes or to provide an example of food styling.',
  // },
  // {
  //   label: 'Allergen Tagging',
  //   freeStarter: false,
  //   headChef: 'false',
  //   startUp: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info: 'Ensure your recipes have the correct Allergen Tagging.',
  // },
  // {
  //   label: 'Import / Export Recipes',
  //   freeStarter: false,
  //   headChef: 'false',
  //   startUp: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info:
  //     'Import Recipes from other platforms or export your recipes from our platform to a CSV file. You have worked hard on your recipes & we want to ensure, no matter our future together, your recipes are always yours!',
  // },
  // {
  //   label: 'Metirc Conversion',
  //   freeStarter: false,
  //   headChef: 'false',
  //   startUp: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info:
  //     'Have a great recipe using the wrong metrics? We have you covered, quickly create & convert your recipe metrics to align with your measurement style.',
  // },
];

export const calculations = [
  {
    label: 'Food Cost Percentage',
    freeStarter: false,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'Calculate Food Cost Percentages instantly, factoring yearly average food costs or your edited ingredient costs',
  },
  {
    label: 'Add Staff Costs',
    freeStarter: false,
    headChef: false,
    startUp: true,
    scaleOut: true,
    info: `Easily add your staff costs to calculate recipe costs accurately.`,
  },
  {
    label: 'Add Venue Costs',
    freeStarter: false,
    headChef: false,
    startUp: true,
    scaleOut: true,
    info: 'Easily add your venue costs to calculate recipe costs accurately.',
  },

  {
    label: 'Recipe Profit',
    freeStarter: false,
    headChef: false,
    startUp: true,
    scaleOut: true,
    info: `Clearly see your recipe profit per serve & how your improvements compound over time`,
  },
  {
    label: 'Recipe Costings',
    freeStarter: false,
    headChef: false,
    startUp: true,
    scaleOut: true,
    info: `Clearly see all your recipe costs to know accurately, which elements are affecting profitability.`,
  },
  {
    label: 'Profit Differences',
    freeStarter: false,
    headChef: false,
    startUp: true,
    scaleOut: true,
    info:
      'Immediately see the profit differences between each recipe version you create.',
  },
  // {
  //   label: 'Yield Targets',
  //   freeStarter: false,
  //   headChef: 'Coming Soon',
  //   startUp: false,
  //   scaleOut: 'Coming Soon',
  //   info: '',
  // },
  // {
  //   label: 'Menu Profitability',
  //   freeStarter: false,
  //   headChef: 'Coming Soon',
  //   startUp: false,
  //   scaleOut: 'Coming Soon',
  //   info:
  //     'Create Menus using from your recipes to understand profiablity across your entire menu. Quickly create menus for catering or special events & know what needs to be improved to be profitable.',
  // },
];

export const advanced = [
  {
    label: 'Recipe Privacy',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'Privacy is a must! All our recipes are encrypted to ensure your reicpes are safe.',
  },
  {
    label: 'Data Protection & Backups',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info: 'All recipes are backed up daily to protect your data.',
  },
  {
    label: 'Bulk Ingredient Upload',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'Who wants to upload ingredients one by one when you can upload all of them at once. Click the bulk upload button on the ingredients page to get started.',
  },
  {
    label: 'Add recipes as ingredients',
    freeStarter: true,
    headChef: true,
    startUp: true,
    scaleOut: true,
    info:
      'We all make recipes that can be added to other recipes, sauces, species you get the point. We have enabled any recipe to be added to another recipe.',
  },
  {
    label: 'Pricing recommendations',
    freeStarter: false,
    headChef: false,
    startUp: true,
    scaleOut: true,
    info:
      'We make pricing simple by giving you a cost based sales price to ensure you are always profitable. This price is a minimum sales price recommendation to be profitable.',
  },
  {
    label: 'Recipe Target Margins',
    freeStarter: false,
    headChef: false,
    startUp: true,
    scaleOut: true,
    info:
      'Ensure your recipes meet your target margins. We will provide alerts to you if a recipe falls below your target margins at any time.',
  },

  {
    label: 'Ingredient Timelines',
    freeStarter: false,
    headChef: 'Coming soon',
    startUp: 'Coming soon',
    scaleOut: 'Coming soon',
    info:
      'Ingredient costs change over time, we keep track of these changes for you to help you when negociating with suppliers.',
  },
  // {
  //   label: 'Sell Recipes',
  //   freeStarter: false,
  //   headChef: 'Coming Soon',
  //   startUp: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info: `We all love to create new recipes & some times these recipes don't fit within our venue's menu. So why not sell these recipes to other chefs looking to save time in their recipe creation process.`,
  // },

  // {
  //   label: 'Calculate delivery costs',
  //   freeStarter: false,
  //   headChef: 'false',
  //   startUp: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info:
  //     'Delivery costs often make our recipes not profitable. Add delivery costs to your recipes to ensure you are pricing them correctly.',
  // },

  // {
  //   label: 'Automatic Shopping Lists',
  //   freeStarter: false,
  //   headChef: 'false',
  //   startUp: 'Coming Soon',
  //   scaleOut: 'Coming Soon',
  //   info:
  //     'We automatically create a shopping list of all your ingredients across your entire menu so you can order food confidently. In the future this will include future sales estimates based on historic data.',
  // },
  // {
  //   label: 'Point of sale integration',
  //   freeStarter: false,
  //   startUp: false,
  //   headChef: false,
  //   scaleOut: 'Coming Soon',
  //   info:
  //     'Measure profitability & popularity of each menu item by adding your recipes from on your POS to improve your sales strategy.',
  // },
];

export const extras = [
  // {
  //   label: 'Nutritional Labeling',
  //   content: 'Add to any account for $10 per month',
  //   info:
  //     'Automatically calculate the nutritional quality of every recipe, then select a design label to print or download.',
  // },
  // {
  //   label: 'Add Staff',
  //   content: 'Add to your business account for $5 per month per user',
  //   info:
  //     'To increase creativity & collaboration. When you have a business account you can add mulitple staff into the one account. Use this option to add more staff.',
  // },
];

export const getSliderMarks = (isBusinessAccount: boolean) =>
  isBusinessAccount
    ? [
        {
          value: -25,
          label: '-0.25c',
        },
        {
          value: 0,
          label: '0',
        },
        {
          value: 25,
          label: '0.25c',
        },
        {
          value: 50,
          label: '0.50c',
        },
        {
          value: 75,
          label: '0.75c',
        },
        {
          value: 100,
          label: '$1.00',
        },
      ]
    : [
        {
          value: -25,
          label: '-0.25c',
        },
        {
          value: 0,
          label: '0',
        },
        {
          value: 25,
          label: '0.25c',
        },
        {
          value: 50,
          label: '0.50c',
        },
        {
          value: 75,
          label: '0.75c',
        },
        {
          value: 100,
          label: '$1.00',
        },
      ];

export const FAQs = [
  // {
  //   question: 'Can I just pay month to month, with no lock in contract?',
  //   answer:
  //     'Absolutely you can. Many chefs do this to test out which features work best for them. Also note that you can export all your recipes. So if you do leave you wont loose all the recipes you have created.',
  // },
  {
    question: 'What are Recipe Versions?',
    answer: `We want you to be as creativie as possible! So instead of recreating the same recipe over and over with slight differences to understand how these changes affect flavour and profitability. We developed a simple way to duplicate your current recipe to create multiple 'recipe versions' allowing you to focus on the small changes of creativity.`,
  },
  {
    question: 'Do I need to add all my ingredient costs in?',
    answer: `All our ingredients are crowd sourced (All ingredient data is shared across all users anonymously), meaning that you don't have to enter in all your ingredient costs before starting. To increase the accurate of your recipe calculations you can edit or add new ingredients at any time.`,
  },
  {
    question:
      'If I close my account, what happens to all the recipes I created?',
    answer:
      'Great question! We have a simple export tool to export all your recipes from our platform into a CSV file. You have worked hard on your recipes & we want to ensure, no matter our future together, your recipes are always yours!',
  },
  {
    question:
      'I like to create recipes, do I need to share all my recipes with every venue I work for?',
    answer:
      'Your recipes are your intellectual property. Therefore you will always have control over which venue you want to share your recipes with.',
  },
  // {
  //   question: '',
  //   answer: '',
  // },
];
