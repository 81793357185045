import styled from 'styled-components';
import {
  IH1Props,
  IH2Props,
  IH3Props,
  IH4Props,
  IH5Props,
  ILabelProps,
  ISpanProps,
  ListGroupProps,
  ListGroupRowProps,
  ILinkProps,
  IColTitleProps,
} from './typefaces.types';
import { Link } from 'react-router-dom';

export const H1 = styled.h1<IH1Props>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
`;

export const H2 = styled.h2<IH2Props>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ fontSize, theme }) =>
    fontSize ? theme.fontSize[fontSize] : theme.fontSize.heading};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
  margin: ${({ noMargin }) => (noMargin ? 0 : 'inherit')};
`;

export const H3 = styled.h3<IH3Props>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
  line-height: ${({ noLineHeight }) => (noLineHeight ? 'auto' : '40px')};
`;

export const H4 = styled.h4<IH4Props>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
`;

export const H5 = styled.h5<IH5Props>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
`;

export const Label = styled.span<ILabelProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ fontSize, theme }) =>
    fontSize ? theme.fontSize[fontSize] : theme.fontSize.default};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
  display: ${({ display }) => (display ? display : 'inline')};
  word-break: ${({ wrap }) => (wrap ? 'break-word' : 'inherit')};
  &.hasToolTipIcon {
    position: relative;
  }
`;

export const Span = styled.span<ISpanProps>`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ fontSize, theme }) =>
    fontSize ? theme.fontSize[fontSize] : theme.fontSize.default};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
  line-height: ${({ fontSize, theme }) =>
    fontSize ? theme.lineHeight[fontSize] : theme.fontSize.default};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'inherit')};

  &.blur {
    cursor: pointer;

    &:after {
      content: ' ';
      display: block;
      width: 107%;
      height: 20px;
      position: absolute;
      top: -1px;
      /* z-index: ${({ theme }) => theme.zIndex.preAbove}; */
      right: -2px;
      backdrop-filter: blur(3px);
      border-radius: 10px;
    }
  }

  &.largeBlur {
    cursor: pointer;

    &:after {
      content: ' ';
      display: block;
      width: 107%;
      height: 40px;
      position: absolute;
      top: -1px;
      /* z-index: ${({ theme }) => theme.zIndex.preAbove}; */
      right: -2px;
      backdrop-filter: blur(3px);
      border-radius: 10px;
    }
  }

  &:hover {
    &.hasSeeIcon {
      cursor: pointer;
      position: relative;

      &:before {
        content: 'SEE';
        position: absolute;
        background: ${({ theme }) =>
          `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
        border-radius: 8px;
        top: -3px;
        z-index: ${({ theme }) => theme.zIndex.above};
        color: white;
        font-size: ${({ theme }) => theme.fontSize.small};
        border-radius: 20px;
        padding: 3px 10px;
      }
    }
  }
`;

export const P = styled.p<ISpanProps>`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ fontSize, theme }) =>
    fontSize ? theme.fontSize[fontSize] : theme.fontSize.default};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
  line-height: ${({ fontSize, theme }) =>
    fontSize ? theme.lineHeight[fontSize] : theme.fontSize.default};
  white-space: ${({ noWrap }) => (noWrap ? 'nowrap' : 'inherit')};

  &.blur {
    cursor: pointer;

    &:after {
      content: ' ';
      display: block;
      width: 107%;
      height: 20px;
      position: absolute;
      top: -1px;
      /* z-index: ${({ theme }) => theme.zIndex.preAbove}; */
      right: -2px;
      backdrop-filter: blur(3px);
      border-radius: 10px;
    }
  }

  &.largeBlur {
    cursor: pointer;

    &:after {
      content: ' ';
      display: block;
      width: 107%;
      height: 40px;
      position: absolute;
      top: -1px;
      /* z-index: ${({ theme }) => theme.zIndex.preAbove}; */
      right: -2px;
      backdrop-filter: blur(3px);
      border-radius: 10px;
    }
  }

  &:hover {
    &.hasSeeIcon {
      cursor: pointer;
      position: relative;

      &:before {
        content: 'SEE';
        position: absolute;
        background: ${({ theme }) =>
          `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
        border-radius: 8px;
        top: 2px;
        z-index: ${({ theme }) => theme.zIndex.above};
        color: white;
        font-size: ${({ theme }) => theme.fontSize.small};
        border-radius: 20px;
        padding: 3px 10px;
      }
    }
  }
`;

export const A = styled(Link)<ILinkProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ fontSize, theme }) =>
    fontSize ? theme.fontSize[fontSize] : theme.fontSize.default};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
  text-decoration: none;
`;

export const Anchor = styled.a<ILinkProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ fontSize, theme }) =>
    fontSize ? theme.fontSize[fontSize] : theme.fontSize.default};
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight ? theme.fontWeight[fontWeight] : theme.fontWeight.regular};
  color: ${({ color, theme }) =>
    color ? theme.colors.text[color] : theme.colors.text.default};
  text-decoration: none;
  line-height: ${({ theme }) => theme.lineHeight.large};
`;

export const ListGroup = styled.ul<ListGroupProps>`
  border-top: ${({ noBorder, theme }) =>
    noBorder ? 0 : `1px solid ${theme.colors.default.default}`};
  padding: ${({ noPadding }) => (noPadding ? 0 : '10px 0 !important')};
`;

export const ListGroupRow = styled.li<ListGroupRowProps>`
  height: 40px;
  align-items: center;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: ${({ colOneWidth, colTwoWidth, colThreeWidth }) => {
    const cOneWidth = colOneWidth ? colOneWidth : '';
    const cTwoWidth = colTwoWidth ? colTwoWidth : '';
    const cThreeWidth = colThreeWidth ? colThreeWidth : '';
    return `${cOneWidth && cOneWidth} ${cTwoWidth && cTwoWidth} ${
      cThreeWidth && cThreeWidth
    }`;
  }};
`;

export const TextGroup = styled.div`
  display: grid;
  grid-template-columns: auto 55px;
  align-items: baseline;
`;

export const ColTitle = styled(Span)<IColTitleProps>`
  font-weight: ${({ theme }) => theme.fontWeight.default};
  color: ${({ selected, theme }) =>
    selected ? theme.colors.text.default : theme.colors.text.grey};
  margin-right: 15px;
  line-height: 20px;
`;

export const ErrorMessage = styled(Span)`
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-weight: ${({ theme }) => theme.fontWeight.default};
  font-size: ${({ theme }) => theme.fontSize.default};
  display: block;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.text.error};
`;
