import { WastageUnitType } from '../generated/graphql';

export const mockData = {
  recipeDetails: [
    {
      name: 'Ingredient Name',
      quantity: '0.23',
      units: '40',
      total: '0.18',
    },
    {
      name: 'Process Time',
      quantity: '0.23',
      units: '40',
      total: '0.18',
    },
    {
      name: 'Staff Time',
      quantity: '0.23',
      units: '40',
      total: '0.18',
    },
    {
      name: 'Ingredient Name',
      quantity: '0.23',
      units: '40',
      total: '0.18',
    },
    {
      name: 'Process Time',
      quantity: '0.23',
      units: '40',
      total: '0.18',
    },
    {
      name: 'Staff Time',
      quantity: '0.23',
      units: '40',
      total: '0.18',
    },
  ],
  ingredientResults: [
    {
      name: 'Apple',
      recipeCost: '0.23',
      recipeGram: '40',
      purchaseCost: '0.18',
      purchaseGram: '100',
      contribution: '17',
    },
    {
      name: 'Apple',
      recipeCost: '0.23',
      recipeGram: '40',
      purchaseCost: '0.18',
      purchaseGram: '100',
      contribution: '17',
    },
    {
      name: 'Apple',
      recipeCost: '0.23',
      recipeGram: '40',
      purchaseCost: '0.18',
      purchaseGram: '100',
      contribution: '17',
    },
  ],
  compareRecipe: [
    {
      name: 'Max Flavour',
      profitPerServe: '3.44',
      serves: '5',
      profitPerMin: '1.59',
      foodCost: '18',
    },
    {
      name: 'Reduce Ingredients',
      profitPerServe: '3.44',
      serves: '5',
      profitPerMin: '1.59',
      foodCost: '18',
    },
    {
      name: 'Double Serves',
      profitPerServe: '3.44',
      serves: '10',
      profitPerMin: '1.59',
      foodCost: '18',
    },
  ],
  recipes: [
    {
      name: 'Avo Salad',
      roi: '10',
      recipeRevenue: '4,059',
      contribution: '18',
    },
    {
      name: 'Pad Thai',
      roi: '14.5',
      recipeRevenue: '3,044',
      contribution: '3.3',
    },
    {
      name: 'Green Curry',
      roi: '13.8',
      recipeRevenue: '5,837',
      contribution: '8.2',
    },
    {
      name: 'Arepa',
      roi: '23',
      recipeRevenue: '8,191',
      contribution: '54',
    },
  ],
  venues: [
    {
      name: 'By Kalindi',
      recipeRevenue: '22,000',
      profitMargin: '15',
    },
    {
      name: 'Plain Greens',
      recipeRevenue: '18,000',
      profitMargin: '9',
    },
    {
      name: 'Pine Nuts & Mint',
      recipeRevenue: '19,000',
      profitMargin: '12',
    },
  ],
  ingredientList: [
    {
      name: 'Ginger',
      supplier: 'Real Foods Direct',
      brand: 'Organic Foods',
      purchaseCost: '17.00',
      purchaseGram: '1000',
      contribution: '10',
    },
    {
      name: 'Coconut Rice',
      supplier: 'Real Foods Direct',
      brand: 'Farmers Are Us',
      purchaseCost: '19.00',
      purchaseGram: '1000',
      contribution: '53',
    },
    {
      name: 'Pine Nuts',
      supplier: 'Real Foods Direct',
      brand: 'Arturs Friend',
      purchaseCost: '18.00',
      purchaseGram: '1000',
      contribution: '2',
    },
    {
      name: 'Avocado',
      supplier: 'Real Foods Direct',
      brand: 'Fresh Fruit',
      purchaseCost: '17.00',
      purchaseGram: '1000',
      contribution: '22',
    },
  ],
  ingredientDetails: {
    brands: [
      {
        name: 'By Kalindi',
        phone: '0428 438 348',
        email: 'tom@reciperevenue.com',
        website: 'www.reciperevenue.com',
      },
      {
        name: 'Plain Greens',
        phone: '0428 438 348',
        email: 'tom@reciperevenue.com',
        website: 'www.reciperevenue.com',
      },
      {
        name: 'Pine Nuts & Mint',
        phone: '0428 438 348',
        email: 'tom@reciperevenue.com',
        website: 'www.reciperevenue.com',
      },
    ],
    suppliers: [
      {
        name: 'Farm to you',
        phone: '0428 438 348',
        email: 'tom@reciperevenue.com',
        website: 'www.reciperevenue.com',
        cost: '32.500',
        amount: '1000',
        contribution: '16',
      },
      {
        name: 'Greens Fields',
        phone: '0428 438 348',
        email: 'tom@reciperevenue.com',
        website: 'www.reciperevenue.com',
        cost: '32.5',
        amount: '1000',
        contribution: '16',
      },
      {
        name: 'Key Seed',
        phone: '0428 438 348',
        email: 'tom@reciperevenue.com',
        website: 'www.reciperevenue.com',
        cost: '32.5',
        amount: '1000',
        contribution: '16',
      },
    ],
  },
  notifications: [
    {
      type: 'Monthly Supplier News',
      name: 'news',
      description:
        'Newsletter highlighting stories about suppliers in the hospitality industry',
      subscribed: false,
    },
    {
      type: 'Ingredient Supplier Specials',
      name: 'ingredient',
      description:
        'Get specials from all suppliers in our network relevant to the ingredients you are using',
      subscribed: false,
    },
    {
      type: 'Packaging Supplier Specials',
      name: 'packaging',
      description:
        'Get specials from all suppliers in our network relevant to the ingredients you are using',
      subscribed: false,
    },
    {
      type: 'Recipe Revenue Updates',
      name: 'Updates',
      description:
        'We are always updating our platform, be the first to know about new features & tips',
      subscribed: false,
    },
  ],
  ingredients: [
    { label: 'Apple', value: 'apple' },
    { label: 'Pear', value: 'pear' },
    { label: 'Avocado', value: 'avocado' },
    { label: 'Banana', value: 'banana' },
  ],
  metricUnits: [
    { label: 'cup', value: 'cup' },
    { label: 'gram', value: 'gram' },
    { label: 'teaspoon', value: 'teaspoon' },
    { label: 'tablespoon', value: 'tablespoon' },
    { label: 'whole', value: 'whole' },
  ],
  metricUnitsPural: [
    { label: 'cups', value: 'cup' },
    { label: 'grams', value: 'gram' },
    { label: 'teaspoons', value: 'teaspoon' },
    { label: 'tablespoons', value: 'tablespoon' },
    { label: 'whole', value: 'whole' },
  ],
  timeUnits: [
    { label: 'second', value: 'second' },
    { label: 'minute', value: 'minute' },
    { label: 'hour', value: 'hour' },
    { label: 'day', value: 'day' },
  ],
  timeUnitsPural: [
    { label: 'seconds', value: 'second' },
    { label: 'minutes', value: 'minute' },
    { label: 'hours', value: 'hour' },
    { label: 'days', value: 'day' },
  ],
  accountInfo: {
    fullName: { label: 'Fullname', value: 'Doug Willias' },
    email: { label: 'Email', value: 'mail@dougwilliams.com' },
    phone: { label: 'Mobile', value: '0428 438 348' },
    position: { label: 'Position', value: 'CHEF' },
    location: { label: 'Location', value: 'Melbourne' },
    hoursSpent: { label: 'Time Invested', value: '35h' },
    roi: { label: 'Return On Investment', value: '12,504' },
    bestRecipe: { label: 'Best Recipe', value: 'Avo Smoothie' },
    created: { label: 'Created Account', value: '01/07/2020' },
  },
  jobPositions: [
    { label: 'Chef', value: 'CHEF' },
    { label: 'Employee', value: 'EMPLOYEE' },
    { label: 'Venue Manager', value: 'MANAGER' },
    { label: 'Other', value: 'OTHER' },
    { label: 'Owner', value: 'OWNER' },
  ],
  venueTypes: [
    { label: 'Bar', value: 'bar' },
    { label: 'Cafe', value: 'cafe' },
    { label: 'Manufacturing', value: 'manufacturing' },
    { label: 'Restaurant', value: 'restaurant' },
    { label: 'Retail', value: 'retail' },
    { label: 'Personal', value: 'personal' },
  ],
  weeksOpenUnits: [{ label: 'per Year', value: 'year' }],
  prepTimeUnits: [{ label: 'hours per Week', value: 'week' }],
  wastageUnits: [{ label: '%', value: WastageUnitType.Percentage }],
  costUnits: [
    { label: 'per Week', value: 'week' },
    { label: 'per Month', value: 'month' },
    { label: 'per Quarter', value: 'quarter' },
    { label: 'per Year', value: 'year' },
  ],
  chefCostUnits: [{ label: 'per Hour', value: 'hour' }],
};
