export const restrictionPopupMessages = {
  // on the account screen, when clicking 'add venue'
  cannotAddVenueOnAccountScreenDueToMaxVenuesLimit: {
    heading: 'Want to add a venue?',
    subHeading: 'Upgrade your account to add another venue',
    buttonText: 'Upgrade account',
  },

  // on the account screen, when clicking 'add venue'
  cannotAddVenueOnMainMenuDueToMaxVenuesLimit: {
    heading: 'Want to add a venue?',
    subHeading: 'Upgrade your account to add a venue.',
    buttonText: 'Upgrade account',
  },

  // when downgrading account
  cannotDowngradeAccount: (userMessages: string[]) => ({
    heading: `Can't downgrade account due to:`,
    subHeading: userMessages.join(', '),
    buttonText: 'Keep account',
  }),

  // when saving a recipe
  cannotSavePriceAndWeeklySalesOnRecipeScreenDueToMaxProfit: {
    heading: 'Upgrade account required',
    subHeading: 'Maxium profit increase reached, upgrade account to continue',
    buttonText: 'Upgrade account',
  },

  // when saving a recipe
  cannotSaveRecipeDueToMaxProfit: {
    heading: 'Upgrade account required',
    subHeading: 'Maxium profit increase reached, upgrade account to continue',
    buttonText: 'Upgrade account',
  },

  // When duplicating recipe versions
  cannotSelectRecipeVersionDueToMaxRecipeVersions: {
    heading: 'Upgrade account required',
    subHeading: 'Max recipe versions reached, upgrade account to continue',
    buttonText: 'Upgrade account',
  },

  // See recipe prodit & costs
  cannotDisplayRecipeVersionProfit: {
    heading: 'Upgrade account required',
    subHeading: `Upgrade your account to 'Recipe Revenue' to see recipe results including profit projections & costs`,
    buttonText: 'Upgrade account',
  },

  // See recipe journey
  cannotSeeRecipeJourney: {
    heading: 'Upgrade account required',
    subHeading: `Upgrade your account to 'Recipe Revenue' to see recipe results including recipe journey`,
    buttonText: 'Upgrade account',
  },

  // See recipe journey
  cannotSeeRecipeRecommendations: {
    heading: 'Upgrade account required',
    subHeading: `Upgrade your account to 'Recipe Revenue' to see recipe results including recipe recommendations`,
    buttonText: 'Upgrade account',
  },

  // See recipe profit vs. popularity
  cannotSeeProfitVsPopularity: {
    heading: 'Upgrade account required',
    subHeading: `Upgrade your account to 'Recipe Revenue' to see recipe results including Profit vs. Popularity`,
    buttonText: 'Upgrade account',
  },

  // See profit journey overtime
  cannotSeeProfitChangesOvertime: {
    heading: 'Upgrade account required',
    subHeading: `Upgrade your account to 'Recipe Revenue' to see recipe results including profit changes overtime`,
    buttonText: 'Upgrade account',
  },

  // When selecting a recipe version
  cannotSelectRecipeVersionDueToMaxProfit: {
    heading: 'Upgrade account required',
    subHeading: 'Max profit increase reached, upgrade account to continue',
    buttonText: 'Upgrade account',
  },

  // When clicking 'scale recipe' on recipe screen
  cannotScaleRecipeOnRecipeScreenDueToMaxScaledVersions: {
    heading: 'Account Limit Reached',
    subHeading: 'To scale your recipes, upgrade account',
    buttonText: 'Upgrade account',
  },

  // When clicking 'scale recipe' on the dots menu when editing a recipe version
  cannotScaleRecipeOnEditRecipeVersionDueToMaxScaledVersions: {
    heading: 'Account Limit Reached',
    subHeading: 'To scale your recipes, upgrade account',
    buttonText: 'Upgrade account',
  },

  // When saving a new recipe, and the price / weekly sales popup shows
  cannotSavePriceAndWeeklySalesOnPopupDueToMaxProfit: {
    heading: 'Account Limit Reached',
    subHeading: 'Increase account profit limmit, upgrade account',
    buttonText: 'Upgrade account',
  },
};
