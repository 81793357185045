import React, { useContext } from 'react';
import { LayoutPage } from '../../shared/layout';
import { Button } from '../../shared/button';
import { Header, Ingredients, Recipes, Users } from './sections';
import {
  AccountType,
  useBrandsQuery,
  useIngredientsQuery,
  useSuppliersQuery,
  useUsersQuery,
  useVenuesQuery,
} from '../../../generated/graphql';
import { getUserStats } from '../../../utils/helper';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const { account } = useContext(LayoutContext);
  const { push } = useHistory();
  if (account?.type !== AccountType.RecipeRevenue) {
    push('/');
  }
  const users = useUsersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const venues = useVenuesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const brands = useBrandsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const suppliers = useSuppliersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const ingredients = useIngredientsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const stats = getUserStats({ users, venues, brands, suppliers, ingredients });

  return (
    <LayoutPage
      align="center"
      withLine
      heading="Dashboard"
      subHeading="Overview on business progress & activity"
      actionArray={[
        <Button color="primary" onClick={null}>
          Users
        </Button>,
      ]}
    >
      <Header stats={stats} />
      <Users stats={stats} />
      <Recipes stats={stats} />
      <Ingredients stats={stats} />
    </LayoutPage>
  );
};

export default Dashboard;
