import React, { FC, useContext, useState } from 'react';
import {
  AccountType,
  AddOnApp,
  BillingCycle,
  MeQuery,
  MeQueryVariables,
} from '../../../../../generated/graphql';
import * as ApolloClient from '@apollo/client';
import { plansArray } from '../../../../../utils/helper/strings';
import { PlanOption } from '../planOption/PlanOption';
import * as S from './PlanOptions.styles';
import { H3 } from '../../../../shared/typefaces/Typefaces.styles';
import {
  INewAccountAddOnProps,
  LayoutContext,
} from '../../../../../contexts/layoutContext';
import { Modal } from '../../../../shared/layout';

interface IPlanOptionsProps {
  isLoading: boolean;
  accountTypePreview?: AccountType;
  me?: ApolloClient.QueryResult<MeQuery, MeQueryVariables>;
  onSubmit: (accountType: AccountType) => void;
}

export interface IBaseAccountModalProps {
  show: boolean;
  heading: string;
  content: string;
}

export const PlanOptions: FC<IPlanOptionsProps> = ({
  isLoading,
  accountTypePreview,
  me,
  onSubmit,
}) => {
  const { newAccount, dispatch } = useContext(LayoutContext);
  const [calcAddOnSelected, setCalcAddOnSelected] = useState(false);
  const initialInfoState = {
    show: false,
    heading: 'Confirm Base Account',
    content:
      'You have selected the Recipe Revenue Account which enables you to add Calculations to your recipes when it suits you. Please confirm which base account you would like to use to enable this feature',
  };

  const [baseAccountModal, setBaseAccountModal] = useState<
    IBaseAccountModalProps
  >(initialInfoState);

  const closeBaseAccountModal = () => {
    setCalcAddOnSelected(false);
    setBaseAccountModal(initialInfoState);
  };

  const openBaseAccountModal = (addOn: AddOnApp) => {
    setCalcAddOnSelected(true);
    const alreadyHasAddonType = newAccount?.addOns?.find((a) => {
      return a.app === addOn;
    });

    const updatedAddOns: INewAccountAddOnProps[] = alreadyHasAddonType
      ? newAccount!.addOns!.map((a) => {
          if (a.app === addOn) {
            // Update addOn
            return {
              ...a,
              updateAddOn: true,
            };
          }
          // Skip addOn
          return a;
        })
      : // New addOn
        [
          {
            app: addOn,
            endDate: '',
            updateAddOn: true,
          },
        ];

    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: {
        ...newAccount,
        addOns: updatedAddOns,
      },
    });

    setBaseAccountModal({ ...initialInfoState, show: true });
  };

  const setBillingCycle = (billingCycle: BillingCycle) => {
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: { ...newAccount, billingCycle },
    });
  };

  const confirmBaseAccount = (
    <S.ModalForm>
      {plansArray.map((p, idx) => {
        return idx < 2 ? (
          <PlanOption
            key={p.type}
            isLoading={isLoading}
            accountTypePreview={accountTypePreview}
            onSubmit={onSubmit}
            openBaseAccountModal={openBaseAccountModal}
            confirmSelection
            {...p}
          />
        ) : null;
      })}
    </S.ModalForm>
  );

  return (
    <S.Container>
      <S.Header>
        <H3 className="heading">Plan Options</H3>
        <S.BillingCycle
          selected={newAccount?.billingCycle === BillingCycle.Yearly}
        >
          <S.Tab
            selected={newAccount?.billingCycle === BillingCycle.Monthly}
            className="tab"
            onClick={() => setBillingCycle(BillingCycle.Monthly)}
          >
            Monthly
          </S.Tab>
          <S.Tab
            selected={newAccount?.billingCycle === BillingCycle.Yearly}
            className="tab"
            onClick={() => setBillingCycle(BillingCycle.Yearly)}
          >
            Yearly
          </S.Tab>
        </S.BillingCycle>
      </S.Header>
      <S.Plans>
        <S.Left>
          {plansArray.map((p, idx) => {
            return idx < 2 ? (
              <PlanOption
                key={p.type}
                isLoading={isLoading}
                accountTypePreview={accountTypePreview}
                onSubmit={onSubmit}
                openBaseAccountModal={openBaseAccountModal}
                calcAddOnSelected={calcAddOnSelected}
                {...p}
              />
            ) : null;
          })}
        </S.Left>
        <S.Right>
          {plansArray.map((p, idx) => {
            return idx > 1 ? (
              <PlanOption
                key={p.type}
                isLoading={isLoading}
                accountTypePreview={accountTypePreview}
                onSubmit={onSubmit}
                openBaseAccountModal={openBaseAccountModal}
                calcAddOnSelected={calcAddOnSelected}
                me={me}
                {...p}
              />
            ) : null;
          })}
        </S.Right>
      </S.Plans>
      <Modal
        showModal={baseAccountModal.show}
        handleClose={closeBaseAccountModal}
        heading={baseAccountModal.heading}
        content={baseAccountModal.content}
        component={confirmBaseAccount}
      />
    </S.Container>
  );
};
