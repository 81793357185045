import { useContext } from 'react';
import { AccountType, AddOnApp } from '../../generated/graphql';
import { LayoutContext } from '../../contexts/layoutContext';
import { validEndDate } from '../helper';
import { useRestrictionCheck } from '../helper/account';

export const useShowCalcData = () => {
  const { account } = useContext(LayoutContext);
  const checkRecipeProfitRestrictions = useRestrictionCheck([
    'CALCULATE_RECIPE_PROFIT',
  ]);
  let showCalcData = false;

  if (account && account?.addOns) {
    const calculatorAddOn = account?.addOns.find(
      (a) => a.app === AddOnApp.Calculator
    );
    const { endDateIsValid } = validEndDate(calculatorAddOn?.endDate);
    const restrictionCheck = checkRecipeProfitRestrictions({
      data: account?.type === AccountType.BusinessStarter,
    });
    showCalcData = endDateIsValid || restrictionCheck.isPass;
  }
  return {
    showCalcData,
  };
};
