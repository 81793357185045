import React, { FC, useState } from 'react';
import * as S from './VideoPlayer.styles';
import { Modal } from '../layout';
import ReactPlayer from 'react-player';

interface IVideoPlayerProps {
  youtubeVideoID: string;
  authPage?: boolean;
  children: React.ReactElement;
}

export const VideoPlayer: FC<IVideoPlayerProps> = ({
  youtubeVideoID,
  authPage = true,
  children,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => !showModal && setShowModal(true);

  const closeModal = () => {
    setShowModal(false);
  };

  const content = (
    <div className="player-wrapper">
      {showModal && (
        <ReactPlayer
          width="100%"
          height="100%"
          url={`https://youtu.be/${youtubeVideoID}`}
          className="react-player"
          playing
          config={{
            youtube: {
              playerVars: { showinfo: 0 },
            },
          }}
        />
      )}
    </div>
  );

  return (
    <S.Container onClick={openModal}>
      {children}

      {showModal && (
        <Modal
          showModal={showModal}
          maxWidth={1000}
          handleClose={closeModal}
          component={content}
          authPage={authPage}
        />
      )}
    </S.Container>
  );
};
