import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LayoutContext } from '../../../contexts/layoutContext';
import { AccountType as AType, useMeQuery } from '../../../generated/graphql';
import { isEmpty } from '../../../utils/helper';
import { useAccountStats } from '../../../utils/helper/account';
import { Button } from '../../shared/button';
import LayoutPage from '../../shared/layout/layoutPage';
import { LogoLoading } from '../../shared/loading';
import { H3 } from '../../shared/typefaces/Typefaces.styles';
import { Header, Info, Notification, Venues } from './sections';
import { AccountType } from './sections/accountType';
import Invoices from './sections/invoices/Invoices';
import Billing from './sections/billing';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

const Account = () => {
  const { selectedVenueObject, account, user, dispatch } = useContext(
    LayoutContext
  );
  const { push } = useHistory();
  const { setInitialActiveSeconds } = useActiveTimeTracker();

  if (
    user.email?.includes('guestuser') &&
    user.email?.includes('reciperevenue')
  ) {
    push('/sign-up');
  }

  if (account?.type === AType.Guest) {
    push('/recipes');
  }

  const accountStats = useAccountStats();
  const me = useMeQuery({
    fetchPolicy: 'cache-and-network',
  });

  const venues = me.data?.me?.user?.venuesSharedWithYou;

  useEffect(() => {
    if (!selectedVenueObject?.id && !isEmpty(venues)) {
      const selectedVenueData = venues!.find((venue) => venue.selected);

      if (isEmpty(selectedVenueData)) {
        const venueData = {
          id: venues![0].venueId,
          displayName: venues![0].venue.displayName,
          address: venues![0].venue.address,
          email: venues![0].venue.email,
          totalActiveSeconds: venues![0].totalActiveSeconds,
          recipeProfitIncreasePerYear: venues![0].venue
            .recipeProfitIncreasePerYear,
        };
        dispatch({
          type: 'SELECT_VENUE_OBJECT',
          payload: venueData,
        });
        setInitialActiveSeconds(venues![0].totalActiveSeconds);
      }
    }
  }, [selectedVenueObject?.id, venues, dispatch]);

  if (me.loading || !account) {
    return <LogoLoading size={60} />;
  }

  if (me.error) {
    console.log('error', me.error);
    return (
      <div>
        <H3>There was an error accessing your account</H3>
        <Link to="/sign-in">Login to your account</Link>
      </div>
    );
  }

  return (
    <LayoutPage
      align="center"
      withLine
      heading="Account"
      subHeading="Update your venue & personal information"
      actionArray={[
        <Button onClick={() => push('/add-recipe')} color="secondary">
          Add Recipe
        </Button>,
      ]}
    >
      <Header data={me} accountStats={accountStats} />
      <Info data={me} accountStats={accountStats} />
      <AccountType />
      <Venues data={me} />
      <Billing />
      <Invoices />
      <Notification />
    </LayoutPage>
  );
};

export default Account;
