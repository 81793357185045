import React, { FC, useContext } from 'react';
import { H2, Label } from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  Headings,
  HeadingsItem,
  TopSection,
  HeaderNumber,
} from './Header.styles';
import { Button } from '../../../../shared/button';
import { theme } from '../../../../../styles/theme';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { IStatsProps } from '../dashboard.types';

const Header: FC<IStatsProps> = ({ stats }) => {
  const { appWidth } = useContext(LayoutContext);

  return (
    <Container>
      <Headings>
        <HeadingsItem className="title">
          <TopSection>
            <div className="label">
              <Label color="faded" fontSize="small">
                Monthly Signups
              </Label>
            </div>
            {appWidth !== 0 && appWidth < theme.mQ.tablet && (
              <div className="cta">
                <Button onClick={null} color="secondary">
                  Users
                </Button>
              </div>
            )}
          </TopSection>{' '}
          <H2>{stats?.totalMonthlySignUps}</H2>
        </HeadingsItem>
      </Headings>
      <Headings align="right">
        <HeadingsItem>
          <Label className="headerLabel" color="faded" fontSize="small">
            Recipes
          </Label>
          <HeaderNumber fontSize="heading">
            {stats?.totalMonthlyRecipes}
          </HeaderNumber>
        </HeadingsItem>
        <HeadingsItem>
          <Label className=" headerLabel" color="faded" fontSize="small">
            Active Users
          </Label>
          <HeaderNumber fontSize="heading">
            {stats?.totalMonthlyActiveUsers}
          </HeaderNumber>
        </HeadingsItem>
        <HeadingsItem>
          <Label className=" headerLabel" color="faded" fontSize="small">
            Enquiries
          </Label>
          <HeaderNumber fontSize="heading">0</HeaderNumber>
        </HeadingsItem>
      </Headings>
    </Container>
  );
};

export default Header;
