import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { H2, H3, H4 } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div`
  overflow: hidden;

  .cta {
    width: 180px;
  }
`;

export const About = styled.div`
  width: 800px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 90px 0;

  ${H3} {
    font-size: ${({ theme }) => theme.fontSize.heading};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.grey};
    text-align: center;

    &.subHeading {
      color: ${({ theme }) => theme.colors.text.faded};
      padding: 0 15px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  padding: 0 15px;

  @media ${device.mobileL} {
    padding: 0 30px;
  }

  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const CTA = styled.div`
  margin-top: 60px;

  ${H2} {
    font-size: ${({ theme }) => theme.fontSize.heading};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    margin-bottom: 30px;
  }

  ${H4} {
    /* font-size: ${({ theme }) => theme.fontSize.heading}; */
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.text.faded};
  }

  @media ${device.mobileL} {
    width: 500px;
  }

  @media ${device.laptop} {
    margin-top: 0;
    margin-left: 60px;
    width: auto;
  }
`;

export const VideoImage = styled.div`
  width: 500px;
  height: 350px;
  text-decoration: none;
  outline: 0;
`;

export const Image = styled.img`
  border-radius: ${({ theme }) => theme.layout.radiusSmall};
  width: 100%;
  height: 100%;
`;
