import React, { useContext } from 'react';
import {
  IEditAccount,
  LayoutContext,
} from '../../../../../contexts/layoutContext';
import {
  SubscriptionStatus,
  useSubscriptionDataQuery,
} from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import {
  getAccountStatus,
  getAccountTypeName,
} from '../../../../../utils/helper/account';
import { Button } from '../../../../shared/button';
import { Card, Content, Header } from '../../../../shared/card';
import { Copy, Share } from '../../../../shared/icons';
import {
  H3,
  Label,
  ListGroup,
  ListGroupRow,
  Span,
} from '../../../../shared/typefaces/Typefaces.styles';
import {
  Container,
  ListColumns,
  StyledSpan,
  StatusSpan,
} from './AccountType.styles';
import { AccountType as AType } from '../../../../../generated/graphql';
import { capitalizeFirstLetterPerWord } from '../../../../../utils/helper';
import {
  formatDateFromTimestamp,
  validEndDate,
} from '../../../../../utils/helper/time';
import { DotsLoading } from '../../../../shared/loading';

export const AccountType = () => {
  const { appWidth, account, dispatch } = useContext(LayoutContext);
  const { data, loading, error } = useSubscriptionDataQuery({
    fetchPolicy: 'network-only',
  });
  const subscriptionData = data?.me?.user;

  if (loading || error || !subscriptionData) {
    const message = (() => {
      if (loading)
        return (
          <DotsLoading
            color="primary"
            size="large"
            isLoading
            className="loadingDots"
          />
        );
      if (error) return `Error: ${error}`;
      return 'Unknown error';
    })();

    return (
      <Container>
        <Card withCardLink>{message}</Card>
      </Container>
    );
  }

  const isInActive =
    subscriptionData?.subscriptionStatus ===
    SubscriptionStatus.SubscriptionInactive;

  const addOns = data?.me?.user?.addOns.filter((a) => {
    const isValid = validEndDate(a.endDate);

    return isValid.endDateIsValid && a;
  });

  const updateAccountType = () => {
    dispatch({
      type: 'SET_EDIT_ACCOUNT',
      payload: {} as IEditAccount,
    });
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: account,
    });
    dispatch({ type: 'SLIDER_PAGE', payload: 'editAccount' });
  };

  return (
    <Container>
      <Card withCardLink>
        <Header
          icon={<Copy size="small" />}
          heading="Account Details"
          subHeading="Information about your selected account & how to change your account type"
          button={
            appWidth !== 0 && appWidth < theme.mQ.tablet ? (
              <Button color="primary" asCircle onClick={updateAccountType}>
                <Share color="white" size="small" />
              </Button>
            ) : (
              <Button
                color={isInActive ? 'primary' : 'default'}
                inversed={isInActive ? false : true}
                onClick={updateAccountType}
              >
                {isInActive ? 'Update Account' : 'Update Account'}
              </Button>
            )
          }
        />
        <Content fullWidth>
          <ListColumns colOneWidth="1fr" colTwoWidth="1fr" padding="small">
            <div>
              <H3 fontWeight="semibold" color="black">
                Account
              </H3>
              <ListGroup className="listGroup">
                <ListGroupRow
                  className="listGroupRow"
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : '100px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">Account Type</Label>
                  <StyledSpan
                    color="primary"
                    type={subscriptionData?.accountType || AType.Guest}
                  >
                    {getAccountTypeName(subscriptionData?.accountType)}
                  </StyledSpan>
                </ListGroupRow>
              </ListGroup>
              <ListGroup noBorder className="listGroup">
                <ListGroupRow
                  className="listGroupRow"
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : '100px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">Account Status</Label>
                  <StatusSpan
                    status={
                      subscriptionData?.subscriptionStatus ||
                      SubscriptionStatus.SubscriptionPending
                    }
                  >
                    {getAccountStatus(subscriptionData?.subscriptionStatus)}
                  </StatusSpan>
                </ListGroupRow>
              </ListGroup>
            </div>
            <div>
              <ListGroupRow
                colOneWidth={
                  appWidth !== 0 && appWidth < theme.mQ.tablet
                    ? '1fr'
                    : appWidth < theme.mQ.laptop
                    ? '150px'
                    : '200px'
                }
                colTwoWidth={
                  appWidth !== 0 && appWidth < theme.mQ.tablet ? '1fr' : 'auto'
                }
              >
                <Label color="faded">
                  <H3 fontWeight="semibold" color="black">
                    Add Ons
                  </H3>
                </Label>
                <Span fontWeight="regular" color="faded">
                  End Date
                </Span>
              </ListGroupRow>
              <ListGroup>
                {addOns?.map((a) => {
                  return (
                    <ListGroupRow
                      key={a.endDate}
                      colOneWidth={
                        appWidth !== 0 && appWidth < theme.mQ.tablet
                          ? '1fr'
                          : appWidth < theme.mQ.laptop
                          ? '150px'
                          : '200px'
                      }
                      colTwoWidth={
                        appWidth !== 0 && appWidth < theme.mQ.tablet
                          ? '1fr'
                          : 'auto'
                      }
                    >
                      <Label color="faded">
                        {capitalizeFirstLetterPerWord(a.app, true)}
                      </Label>
                      <Span>{formatDateFromTimestamp(a.endDate)}</Span>
                    </ListGroupRow>
                  );
                })}
              </ListGroup>
            </div>
          </ListColumns>
        </Content>
      </Card>
    </Container>
  );
};
