import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Card, Header, Content } from '../../../../shared/card';
import { User, Share } from '../../../../shared/icons';
import { theme } from '../../../../../styles/theme';
import { Button } from '../../../../shared/button';
import { ListColumns } from './Info.styles';
import {
  Label,
  Span,
  H3,
  ListGroup,
  ListGroupRow,
} from '../../../../shared/typefaces/Typefaces.styles';
import { mockData } from '../../../../../utils/mockData';
import { IInfoProps } from './info.types';
import {
  convertPercentageCleanly,
  convertTimeFromSecondsCleanly,
  isEmpty,
} from '../../../../../utils/helper';
import { DotsLoading } from '../../../../shared/loading';
import { useActiveTimeTracker } from '../../../../../utils/customHooks/useActiveTimeTracker';

const Info: FC<IInfoProps> = ({ data, accountStats }) => {
  const { appWidth, dispatch } = useContext(LayoutContext);
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const activeTime = convertTimeFromSecondsCleanly(getTotalActiveSeconds());

  const userData = data?.data?.me?.user;

  const handleUpdateProfile = () => {
    dispatch({ type: 'SLIDER_PAGE', payload: 'account' });
  };

  const createdDate = new Date(
    Number(userData?.createdAt)
  ).toLocaleDateString();

  return (
    <Card withCardLink>
      <Header
        icon={<User size="small" />}
        heading={
          appWidth !== 0 && appWidth < theme.mQ.tablet
            ? 'Update Information'
            : 'Personal Information'
        }
        button={
          appWidth !== 0 && appWidth < theme.mQ.tablet ? (
            <Button color="primary" asCircle onClick={handleUpdateProfile}>
              <Share color="white" size="small" />
            </Button>
          ) : (
            <Button color="default" inversed onClick={handleUpdateProfile}>
              Update Information
            </Button>
          )
        }
      />
      <Content fullWidth>
        <DotsLoading
          isLoading={data?.loading}
          size="large"
          lineHeight={10}
          color="default"
        />
        {!data?.loading && !isEmpty(userData) && (
          <ListColumns colOneWidth="1fr" colTwoWidth="1fr" padding="small">
            <div>
              <H3 fontWeight="semibold" color="black">
                Information
              </H3>
              <ListGroup>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : '130px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">
                    {mockData.accountInfo.fullName.label}
                  </Label>
                  <Span>
                    {userData?.firstName} {userData?.lastName}
                  </Span>
                </ListGroupRow>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : '130px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">
                    {mockData.accountInfo.email.label}
                  </Label>
                  <Span>{userData?.email}</Span>
                </ListGroupRow>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : '130px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">Password</Label>
                  <Span>* * * *</Span>
                </ListGroupRow>
              </ListGroup>
            </div>
            <div>
              <H3 fontWeight="semibold" color="black">
                Stats
              </H3>
              <ListGroup>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : appWidth < theme.mQ.laptop
                      ? '150px'
                      : '200px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">Started on</Label>
                  <Span>{createdDate}</Span>
                </ListGroupRow>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : appWidth < theme.mQ.laptop
                      ? '150px'
                      : '200px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">
                    {mockData.accountInfo.bestRecipe.label}
                  </Label>
                  <Span>
                    {accountStats.bestRecipeRevenue.displayName || '-'}
                  </Span>
                </ListGroupRow>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : appWidth < theme.mQ.laptop
                      ? '150px'
                      : '200px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">
                    {mockData.accountInfo.hoursSpent.label}
                  </Label>
                  <Span>{`${activeTime.timeValue} ${activeTime.timeText}`}</Span>
                </ListGroupRow>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : appWidth < theme.mQ.laptop
                      ? '150px'
                      : '200px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">{mockData.accountInfo.roi.label}</Label>
                  <Span>
                    {convertPercentageCleanly(accountStats.avgMarkupPercentage)}
                  </Span>
                </ListGroupRow>
                <ListGroupRow
                  colOneWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : appWidth < theme.mQ.laptop
                      ? '150px'
                      : '200px'
                  }
                  colTwoWidth={
                    appWidth !== 0 && appWidth < theme.mQ.tablet
                      ? '1fr'
                      : 'auto'
                  }
                >
                  <Label color="faded">Profit Increase Per Week</Label>
                  <Span>
                    ${accountStats.totalWeeklyProfitIncrease.toFixed(2)}
                  </Span>
                </ListGroupRow>
              </ListGroup>
            </div>
          </ListColumns>
        )}
      </Content>
    </Card>
  );
};

export default Info;
