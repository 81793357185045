import React, { FC } from 'react';
import {
  blurContent,
  convertCostCleanly,
  convertPercentageCleanly,
} from '../../../../../utils/helper';
import { H2, Span, Label } from '../../../../shared/typefaces/Typefaces.styles';
import { Container, Headings, HeadingsItem } from './Header.styles';
import { IHeaderProps } from './header.types';
import { useShowCalcData } from '../../../../../utils/customHooks/useShowCalcData';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';

const Header: FC<IHeaderProps> = ({ accountStats }) => {
  const { showCalcData } = useShowCalcData();
  const showRestrictionPopup = useRestrictionPopup();

  const handleRecipeRevenueUpgrade = () => {
    showRestrictionPopup(
      restrictionPopupMessages.cannotSeeProfitChangesOvertime
    );
  };

  return (
    <Container>
      <Headings>
        <HeadingsItem className="title">
          <Label className="headerLabel" color="faded" fontSize="small">
            Total Recipe Revenue
          </Label>
          <H2>
            {convertCostCleanly(accountStats.totalRevenuePerYear)}{' '}
            <Label color="faded">per year</Label>
          </H2>
        </HeadingsItem>
      </Headings>
      <Headings align="right">
        <HeadingsItem>
          <Label className="headerLabel" color="faded" fontSize="small">
            Return On Investment
          </Label>
          <Span
            fontSize="heading"
            className={`${!showCalcData && 'largeBlur hasSeeIcon'}`}
            onClick={() => !showCalcData && handleRecipeRevenueUpgrade()}
          >
            {showCalcData
              ? convertPercentageCleanly(accountStats.avgMarkupPercentage)
              : blurContent(
                  convertPercentageCleanly(accountStats.avgMarkupPercentage)
                )}
          </Span>
        </HeadingsItem>
        <HeadingsItem>
          <Label className=" headerLabel" color="faded" fontSize="small">
            Avg Profit Per Recipe
          </Label>
          <Span
            fontSize="heading"
            className={`${!showCalcData && 'largeBlur hasSeeIcon'}`}
            onClick={() => !showCalcData && handleRecipeRevenueUpgrade()}
          >
            {showCalcData
              ? convertCostCleanly(accountStats.avgRecipeProfit)
              : blurContent(convertCostCleanly(accountStats.avgRecipeProfit))}
          </Span>
        </HeadingsItem>
        <HeadingsItem>
          <Label className=" headerLabel" color="faded" fontSize="small">
            Avg Food Cost %
          </Label>
          <Span
            fontSize="heading"
            className={`${!showCalcData && 'largeBlur hasSeeIcon'}`}
            onClick={() => !showCalcData && handleRecipeRevenueUpgrade()}
          >
            {showCalcData
              ? convertPercentageCleanly(accountStats.avgFoodCostPercentage)
              : blurContent(
                  convertPercentageCleanly(accountStats.avgFoodCostPercentage)
                )}
          </Span>
        </HeadingsItem>
      </Headings>
    </Container>
  );
};

export default Header;
