import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useMeQuery, useRecipesQuery } from '../../../generated/graphql';
import { isEmpty } from '../../../utils/helper';
import { useAccountStats } from '../../../utils/helper/account';
import { Button } from '../../shared/button';
import LayoutPage from '../../shared/layout/layoutPage';
import { LogoLoading } from '../../shared/loading';
import { H3 } from '../../shared/typefaces/Typefaces.styles';
import { Header, ProfitPopularity, Recommendations } from './sections';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';
import { GoalJourney } from '../recipes/sections';

const Results = () => {
  const { selectedVenueObject, account, dispatch } = useContext(LayoutContext);
  const { push } = useHistory();
  const { setInitialActiveSeconds } = useActiveTimeTracker();

  const accountStats = useAccountStats();
  const me = useMeQuery({
    fetchPolicy: 'cache-and-network',
  });
  const recipes = useRecipesQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
      },
    },
  });

  const venues = me.data?.me?.user?.venuesSharedWithYou;

  useEffect(() => {
    if (!selectedVenueObject?.id && !isEmpty(venues)) {
      const selectedVenueData = venues!.find((venue) => venue.selected);

      if (isEmpty(selectedVenueData)) {
        const venueData = {
          id: venues![0].venueId,
          displayName: venues![0].venue.displayName,
          address: venues![0].venue.address,
          email: venues![0].venue.email,
          totalActiveSeconds: venues![0].totalActiveSeconds,
          recipeProfitIncreasePerYear: venues![0].venue
            .recipeProfitIncreasePerYear,
        };
        dispatch({
          type: 'SELECT_VENUE_OBJECT',
          payload: venueData,
        });
        setInitialActiveSeconds(venues![0].totalActiveSeconds);
      }
    }
  }, [selectedVenueObject?.id, venues, dispatch]);

  if (me.loading || !account) {
    return <LogoLoading size={60} />;
  }

  if (me.error) {
    console.log('error', me.error);
    return (
      <div>
        <H3>There was an error accessing your account</H3>
        <Link to="/sign-in">Login to your account</Link>
      </div>
    );
  }

  return (
    <LayoutPage
      align="center"
      withLine
      heading="Results"
      subHeading="All your Recipe Revenue Results"
      actionArray={[
        <Button onClick={() => push('/recipes')} color="default" inversed>
          Recipes
        </Button>,
        <Button onClick={() => push('/add-recipe')} color="secondary">
          Add Recipe
        </Button>,
      ]}
    >
      <Header data={me} accountStats={accountStats} />
      <Recommendations data={recipes} withCardLink />
      <ProfitPopularity data={recipes} withCardLink showGraph />
      <GoalJourney data={recipes} showGraph />
    </LayoutPage>
  );
};

export default Results;
