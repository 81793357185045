import React, { useEffect, useContext, useState, useRef } from 'react';
import {
  H3,
  Span,
  H5,
  Anchor,
  H4,
} from '../../shared/typefaces/Typefaces.styles';
import {
  Container,
  ListGroup,
  ListItem,
  Ready,
  Logo,
  Footer,
  Brand,
} from './Homepage.styles';
import logo from '../../../images/logo-min.png';
import AngleBox from '../../shared/layout/angleBox/AngleBox';
import { LayoutPage, BoxGroup } from '../../shared/layout';
import Quote from '../../shared/layout/quote';
import Slides from '../../shared/layout/slides';
import { LayoutContext } from '../../../contexts/layoutContext';
import { About, Header, SideImageInfo, WhereToStart } from './sections';
import { TemplateSelector } from '../../shared/templateSelector';
import { Callout } from './sections/callout';
import { Play } from '../../shared/icons';

const Homepage = (props) => {
  const { dispatch } = useContext(LayoutContext);
  const [calculateRecipe, setCalculateRecipe] = useState(false);

  const aboutRef = useRef<null | HTMLDivElement>(null);
  const pricingRef = useRef<null | HTMLDivElement>(null);
  const preMadeRecipeRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const { history, location } = props;
    if (location?.state) {
      if (
        location?.state === 'about' &&
        aboutRef &&
        aboutRef.current !== null
      ) {
        aboutRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      if (
        location?.state === 'pricing' &&
        pricingRef &&
        pricingRef.current !== null
      ) {
        pricingRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      if (
        location?.state === 'pre-made-recipes' &&
        preMadeRecipeRef &&
        preMadeRecipeRef.current !== null
      ) {
        preMadeRecipeRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      history.push('/');
    }
  }, [props]);

  useEffect(() => {
    dispatch({ type: 'CLOSE_MENU' });
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      setCalculateRecipe(false);
    }, 2500);
  }, [calculateRecipe]);

  return (
    <Container>
      <LayoutPage noBottomPadding align="full">
        <Header />
        <TemplateSelector
          showHeading
          preMadeRecipeRef={preMadeRecipeRef}
          addMarginTop
        />
        <Callout
          heading="Boost Menu Performance by $5,000 profit in 5 hours!"
          subHeading="( recipe profit increase after initial calculation )"
          smallSubHeading
          ctaText={
            <Span color="white" className="seeHow">
              See How! <Play size="small" color="white" />
            </Span>
          }
          ctaLink="#about"
        />
        <SideImageInfo />
        <AngleBox marginTop="small" color="secondary" fullWidth noMarginBottom>
          <Ready>
            <WhereToStart />
          </Ready>
        </AngleBox>
        {/* <Intro /> */}
        <Slides
          className="testimonial"
          interval={6000}
          hideDots
          slides={[
            <Quote
              quote="Finally my creativity is free! I know my costs are accurate which all me to focus on making delicious food xx"
              name="Kalindi Ochoa"
              venue="By Kalindi"
              title="Founder"
            />,
            <Quote
              quote="I once made $65 from a catering job.. Recipe Revenue allows me to accurately plan recipe & staff costs"
              name="Ricky Graw"
              venue="Delicious Catering"
              title="Chef"
            />,
          ]}
        />
        <About aboutRef={aboutRef} />
        <Callout
          heading="Built For Chefs Creating"
          subHeading="Remarkable Food Experiences!"
          ctaText="Start with pre-made recipe"
          ctaLink="#pre-made-recipes"
        />
        <Footer>
          <BoxGroup
            center
            noBackground
            items={[
              <ListGroup>
                <ListItem>
                  <Brand>
                    <div className="brandHeader">
                      <Logo src={logo} />
                      <H4 className="brand">Recipe Revenue</H4>
                    </div>
                    <H3 className="brandSubHeading">
                      Remarkable <br />
                      Food Experiences.
                    </H3>
                  </Brand>
                </ListItem>
              </ListGroup>,
              <ListGroup>
                <ListItem>
                  <H5>Product</H5>
                </ListItem>
                <ListItem>
                  <Anchor href="#about">Founder</Anchor>
                </ListItem>
                <ListItem>
                  <Anchor href="#about">How we work</Anchor>
                </ListItem>
                <ListItem>
                  <Anchor href="/select-account/#features">Features</Anchor>
                </ListItem>
                <ListItem>
                  <Anchor href="#pricing">Pricing</Anchor>
                </ListItem>
              </ListGroup>,
              <ListGroup>
                <ListItem>
                  <H5>Resources</H5>
                </ListItem>
                <ListItem>
                  <Anchor href="/" target="_blank">
                    C4X Principles
                  </Anchor>
                </ListItem>
                <ListItem>
                  <Anchor href="/" target="_blank">
                    Blog
                  </Anchor>
                </ListItem>
                <ListItem>
                  <Anchor href="/" target="_blank">
                    Events
                  </Anchor>
                </ListItem>
                {/* <ListItem>
                  <Anchor
                    href="https://join.slack.com/t/reciperevenue/shared_invite/zt-itjmsjq9-pVyDTqqEwNVxgcrnerxIsQ"
                    target="_blank"
                  >
                    Group Discussions
                  </Anchor>
                </ListItem> */}
                <ListItem>
                  <Anchor href="mailTo:ask@reciperevenue.com?subject=🥕%20Website%20Media%20Question&body=Hi%20Team,%0D%0DI'd%20like%20to%20ask%20a%20question%20about..">
                    Media
                  </Anchor>
                </ListItem>
              </ListGroup>,
              <ListGroup>
                <ListItem>
                  <H5>Contact</H5>
                </ListItem>
                <ListItem>
                  <Anchor href="mailTo:ask@reciperevenue.com?subject=🥕%20Website%20Question&body=Hi%20Team,%0D%0DI'd%20like%20to%20ask%20a%20question%20about..">
                    ask@reciperevenue.com
                  </Anchor>
                </ListItem>
                <ListItem>
                  <Anchor
                    href="https://www.instagram.com/reciperevenue"
                    target="_blank"
                  >
                    Socials
                  </Anchor>
                </ListItem>
                <ListItem>
                  <Anchor href="/privacy-policy">Privacy Policy</Anchor>
                </ListItem>
                <ListItem>
                  <Span>Terms & Conditions</Span>
                </ListItem>
              </ListGroup>,
            ]}
          />
        </Footer>
      </LayoutPage>
    </Container>
  );
};

export default Homepage;
