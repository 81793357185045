import { isEmpty } from './objects';

export function getRandomArrayElement<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function isDefinedAndNotNullFilter<T>(
  item: T
): item is Exclude<T, null | undefined> {
  return item !== undefined && item !== null;
}

export const getAverage: (arr: number[]) => number = (arr) =>
  isEmpty(arr) ? 0 : arr.reduce((a, b) => a + b, 0) / arr.length;

export const getKeyByValue = (array: {}[], key: string, searchValue: any) => {
  for (let idx = 0; idx < array.length; idx++) {
    const obj = array[idx];
    if (obj.hasOwnProperty(key) && obj[key] === searchValue) {
      return idx; // Return the first key that corresponds to the value
    }
  }
  return 0; // Return null if the value is not found in any object
};

export const getFiveGraphElements = (
  data: Array<any>,
  addInitialValue?: boolean
) => {
  if (data.length < 5) {
    const defaultElement = { name: '', totalProfit: undefined, amt: undefined };
    if (addInitialValue) {
      data.unshift({ name: '0 sec', totalProfit: 0, amt: 0 });
    }
    while (data.length < 5) {
      data.push(defaultElement);
    }
  }

  const length = data.length;
  const firstIndex = 0;
  const lastIndex = length - 1;
  const middleIndex = Math.floor(length / 2);
  const middleOfFirstAndMiddleIndex = Math.floor(middleIndex / 2);
  const middleOfMiddleAndLastIndex = Math.floor((middleIndex + lastIndex) / 2);

  const result = [
    data[firstIndex],
    data[middleOfFirstAndMiddleIndex],
    data[middleIndex],
    data[middleOfMiddleAndLastIndex],
    data[lastIndex],
  ];

  return result;
};
