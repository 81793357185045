import { nanoid } from 'nanoid';
import React, { FC, Fragment, memo, useContext, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  AccountType,
  IngredientMetricUnit,
  ItemDeleteType,
  RecipeMetricUnit,
} from '../../../../../generated/graphql';
import { theme } from '../../../../../styles/theme';
import { useRestrictionPopup } from '../../../../../utils/customHooks/useRestrictionPopup';
import { getUnitTotal, scrollItemToCenter } from '../../../../../utils/helper';
import {
  checkIfAccountIsNotComplete,
  useRestrictionCheck,
} from '../../../../../utils/helper/account';
import { getSubRecipeGrams } from '../../../../../utils/helper/getSubRecipeGrams';
import { restrictionPopupMessages } from '../../../../../utils/restrictionPopupMessages';
import { DotsMenu } from '../../../dotsMenu';
import { MenuItem } from '../../../dotsMenu/DotsMenu.styles';
import { Apple, ChefHat, Dots, Timer } from '../../../icons';
import { ButtonGroup } from '../../../layout/Layout.styles';
import { LinkSpanStyled } from '../../../navbar/navbarActions/NavbarActions.styles';
import Table from '../../../table';
import { Label } from '../../../typefaces/Typefaces.styles';
import {
  RecipeFormItem,
  RecipeFormState,
  RecipeIngredientFormState,
} from '../../recipe.types';
import Summary from '../summary';
import { getFormRow } from './getFormRow';
import {
  AddItem,
  Container,
  DotsMenuWrapper,
  HeaderIcon,
} from './Items.styles';
import { IItemsProps, LiquidRecipe } from './items.types';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { getRecipeSummaryStats } from '../../../../../utils/helper/getRecipeSummaryStats';
import { overlayConstants } from '../../../layout/layoutOverlay/constants';

const Items: FC<IItemsProps> = ({
  ingredients,
  recipes,
  disableIngredient,
  roundCorners,
  original,
  initialItems,
  handleDeleteRecipeVersion,
}) => {
  const {
    selectedRecipe,
    scaleRecipe,
    duplicateRecipe,
    appWidth,
    account,
    user,
    dispatch,
  } = useContext(LayoutContext);

  const checkScaleRecipeRestrictions = useRestrictionCheck(['SCALE_RECIPES']);

  const recipeMenuRef = useRef<HTMLDivElement>(null);
  const [recipeMenu, setRecipeMenu] = useState(false);
  const showRestrictionPopup = useRestrictionPopup();
  const { errors, control, watch, setValue } = useFormContext<
    RecipeFormState
  >();
  const { append, remove } = useFieldArray<RecipeFormItem>({
    control,
    name: 'items',
  });

  const serves = watch('serves');
  const fields: RecipeFormItem[] = watch('items');

  const [fieldCount, setFieldCount] = useState(0);
  const [liquidRecipe, setLiquidRecipe] = useState<LiquidRecipe>('');

  const summaryStats = getRecipeSummaryStats(
    fields,
    ingredients.data?.ingredients.ingredients || undefined,
    recipes.data?.venueRecipes.recipes || undefined
  );

  const handleAppend = (
    type: RecipeFormItem['__typename'],
    staffTime?: boolean
  ) => {
    const rowKey = nanoid();

    // @ts-expect-error
    append({ rowKey, __typename: type, staffTime });

    setFieldCount(fields.length);

    scrollItemToCenter(fieldCount, true);
  };

  const handleDelete = (item: Partial<RecipeFormItem>, index: number) => {
    if (item.type === 'DELETE_ITEM') {
      return;
    }

    // if the item is not saved, we simply remove it from the list
    if (!item.recipeItemId) {
      remove(index);
      return;
    }

    const deleteType =
      item.ingredient?.extra === 'recipe'
        ? ItemDeleteType.Recipe
        : item.__typename === 'RecipeIngredient'
        ? ItemDeleteType.Ingredient
        : item.__typename === 'RecipeTimeItem'
        ? ItemDeleteType.Time
        : undefined;

    if (!deleteType) {
      return;
    }

    setLiquidRecipe('');
    remove(index);

    append({
      rowKey: nanoid(),
      type: 'DELETE_ITEM',
      __typename: 'RecipeItemDeleted',
      recipeItemId: item.recipeItemId,
      deleteType,
    });
  };

  const handleDotsClick = () => {
    if (recipeMenu) {
      setRecipeMenu(false);
    } else {
      setRecipeMenu(true);
    }
  };

  const handleDuplicateRecipeVersion = async () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountDuplicateRecipe,
      });
    }

    dispatch({
      type: 'DUPLICATE_RECIPE',
      payload: true,
    });
    dispatch({
      type: 'SCALE_RECIPE',
      payload: false,
    });
    setRecipeMenu(false);
  };

  const handleScaleRecipeVersion = async () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountScaleRecipe,
      });
    }

    const restrictionCheck = checkScaleRecipeRestrictions({
      data: account?.type === AccountType.Registered,
    });

    if (restrictionCheck.isPass) {
      dispatch({
        type: 'DUPLICATE_RECIPE',
        payload: false,
      });
      dispatch({
        type: 'SCALE_RECIPE',
        payload: true,
      });
    } else {
      showRestrictionPopup(
        restrictionPopupMessages.cannotScaleRecipeOnEditRecipeVersionDueToMaxScaledVersions
      );
    }
    setRecipeMenu(false);
  };

  const handleEditRecipeVersion = async () => {
    if (checkIfAccountIsNotComplete(user?.email, account?.type)) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountEditRecipe,
      });
    }
    dispatch({
      type: 'DUPLICATE_RECIPE',
      payload: false,
    });
    dispatch({
      type: 'SCALE_RECIPE',
      payload: false,
    });
    setRecipeMenu(false);
  };

  const handleDeleteVersion = async () => {
    if (
      window.confirm('Are you sure you want to delete this recipe version?')
    ) {
      setRecipeMenu(true);
      handleDeleteRecipeVersion();
    } else {
      setRecipeMenu(false);
    }
  };

  const handleIngredientChange = (
    value: RecipeIngredientFormState['ingredient'],
    name: string
  ) => {
    const index = name.includes('recipe')
      ? name.replace('.recipe', '')
      : name.replace('.ingredient', '');
    if (value) {
      if (value.extra === 'recipe') {
        setValue(index + '.type', 'RECIPE');
        setValue(index + '.unit', RecipeMetricUnit.Serve);
      } else {
        setValue(index + '.type', 'INGREDIENT');
        setValue(index + '.unit', IngredientMetricUnit.Gram);
      }
    }
  };

  const handleIngredientTotalChange = ({
    index,
    subRecipeData,
    metrics,
  }: {
    index: number;
    subRecipeData: { totalGrams: number; serves: number } | undefined;
    metrics:
      | Record<
          string, // metric name
          number // number of grams per unit of this metric
        >
      | undefined;
  }) => {
    const type = fields[index].type;
    const quantity = fields[index].quantity;

    const unit = fields[index].unit;

    if (!unit) return;

    const total =
      type === 'RECIPE'
        ? getSubRecipeGrams({
            unit: unit,
            quantity: Number(quantity),
            totalGrams: subRecipeData?.totalGrams ?? 0,
            recipeServes: subRecipeData?.serves ?? 0,
          })
        : getUnitTotal(unit, Number(quantity), metrics);

    setValue(index + '.totalGrams', total);
  };

  const recipeMenuIcon = (
    <HeaderIcon
      onClick={handleDotsClick}
      showBackground={recipeMenu}
      ref={recipeMenuRef}
    >
      <Dots color="grey" size="smaller" />
    </HeaderIcon>
  );

  const getTableHeadings =
    appWidth < theme.mQ.tablet
      ? [
          {
            icon: <Fragment />,
            name: <Label>Recipe Details</Label>,
            item1: <Fragment />,
            item2: <Fragment />,
            item3: <Fragment />,
            item4: recipeMenuIcon,
          },
        ]
      : [
          {
            icon: <Fragment />,
            name: <Label>Recipe Details</Label>,
            item1: <Label>Quantity</Label>,
            item2: <Label>Units</Label>,
            item3: <Label>Total</Label>,
            item4: recipeMenuIcon,
          },
        ];

  const getTableItems = (provided?: any, snapshot?: any) => {
    return appWidth < theme.mQ.tablet ? (
      <Table
        noMargin={scaleRecipe}
        className="itemsTable"
        iconWidth={10}
        itemOneWidth={75}
        itemTwoWidth={100}
        itemFourWidth={100}
        gridArea="'iconOne name name name name itemFour'
      'iconOne itemOne itemOne itemTwo itemTwo itemThree'"
        headings={getTableHeadings}
        provided={provided}
        snapshot={snapshot}
        rows={fields.map((item, index) =>
          getFormRow({
            item,
            index,
            ingredients: ingredients.data?.ingredients.ingredients || undefined,
            recipes: recipes.data?.venueRecipes.recipes || undefined,
            liquidRecipe,
            selectedRecipe,
            appWidth,
            errors,
            disableIngredient,
            control,
            initialItems,
            accountType: account?.type,
            handleIngredientTotalChange,
            handleIngredientChange,
            handleDelete,
            setValue,
            setLiquidRecipe,
            watch,
          })
        )}
      />
    ) : (
      <Table
        noMargin={scaleRecipe}
        className="itemsTable"
        iconWidth={50}
        itemOneWidth={92}
        itemTwoWidth={127}
        itemThreeWidth={90}
        itemFourWidth={50}
        headings={getTableHeadings}
        provided={provided}
        snapshot={snapshot}
        rows={fields.map((item, index) =>
          getFormRow({
            item,
            index,
            ingredients: ingredients.data?.ingredients.ingredients || undefined,
            recipes: recipes.data?.venueRecipes.recipes || undefined,
            liquidRecipe,
            selectedRecipe,
            appWidth,
            errors,
            disableIngredient,
            control,
            initialItems,
            accountType: account?.type,
            handleIngredientTotalChange,
            handleIngredientChange,
            handleDelete,
            setValue,
            setLiquidRecipe,
            watch,
          })
        )}
      />
    );
  };

  const reorder = (
    list: RecipeFormItem[],
    startIndex: number,
    endIndex: number
  ): RecipeFormItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const reOrderedItems = reorder(fields, source.index, destination.index);
    const updatedOrder = reOrderedItems.map((item, idx) => {
      return {
        ...item,
        order: idx,
      };
    });
    setValue('items', updatedOrder);
  };

  return (
    <Container roundCorners={roundCorners}>
      <DotsMenuWrapper>
        {recipeMenu && (
          <DotsMenu top={50} right={15}>
            {!duplicateRecipe && (
              <MenuItem>
                <LinkSpanStyled onClick={() => handleDuplicateRecipeVersion()}>
                  Duplicate Recipe
                </LinkSpanStyled>
              </MenuItem>
            )}
            {!scaleRecipe && (
              <MenuItem>
                <LinkSpanStyled onClick={() => handleScaleRecipeVersion()}>
                  Scale Recipe
                </LinkSpanStyled>
              </MenuItem>
            )}
            {(duplicateRecipe || scaleRecipe) && (
              <MenuItem>
                <LinkSpanStyled onClick={() => handleEditRecipeVersion()}>
                  Edit Recipe
                </LinkSpanStyled>
              </MenuItem>
            )}
            {!original && !scaleRecipe && (
              <MenuItem onClick={() => handleDeleteVersion()}>
                <LinkSpanStyled color="faded">
                  Delete Recipe Version
                </LinkSpanStyled>
              </MenuItem>
            )}
          </DotsMenu>
        )}
      </DotsMenuWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="items">
          {(provided: any, snapshot: any) => getTableItems(provided, snapshot)}
        </Droppable>
      </DragDropContext>
      <ButtonGroup>
        {!disableIngredient && (
          <AddItem
            color="primary"
            onClick={() => handleAppend('RecipeIngredient')}
          >
            {appWidth < theme.mQ.mobileM ? (
              <Apple size="medium" color="white" />
            ) : appWidth < theme.mQ.tablet ? (
              'Ingredient'
            ) : (
              'Add Ingredient'
            )}
          </AddItem>
        )}
        <AddItem
          color="default"
          onClick={() => handleAppend('RecipeTimeItem')}
          inversed
        >
          {appWidth < theme.mQ.mobileM ? (
            <Timer size="medium" />
          ) : appWidth < theme.mQ.tablet ? (
            'Process Time'
          ) : (
            'Add Process Time'
          )}
        </AddItem>
        <AddItem
          color="default"
          onClick={() => handleAppend('RecipeTimeItem', true)}
          inversed
        >
          {appWidth < theme.mQ.mobileM ? (
            <ChefHat size="medium" />
          ) : appWidth < theme.mQ.tablet ? (
            'Staff Time'
          ) : (
            'Add Staff Time'
          )}
        </AddItem>
      </ButtonGroup>
      <Summary
        totalStaffTime={
          isNaN(summaryStats.totalStaffTime) ? 0 : summaryStats.totalStaffTime
        }
        totalProcessTime={
          isNaN(summaryStats.totalProcessTime)
            ? 0
            : summaryStats.totalProcessTime
        }
        totalTime={isNaN(summaryStats.totalTime) ? 0 : summaryStats.totalTime}
        totalGrams={
          isNaN(summaryStats.totalGrams) ? 0 : summaryStats.totalGrams
        }
        serves={Number(serves)}
        liquidRecipe={liquidRecipe}
      />
    </Container>
  );
};

export default memo(Items);
