import React, { FC, memo, useContext, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { LayoutContext } from '../../../contexts/layoutContext';
import {
  AccountType,
  AddOnApp,
  RecipeDocument,
  useMeQuery,
  useRecipeQuery,
  useUpdateRecipeMutation,
  useVenueQuery,
} from '../../../generated/graphql';
import { currency, getRecipeData, isEmpty } from '../../../utils/helper';
import {
  validateNumberValues,
  validatePositiveNumberValues,
} from '../../../utils/helper/validation';
import { Button } from '../../shared/button';
import LayoutPage from '../../shared/layout/layoutPage';
import { DotsLoading, LogoLoading } from '../../shared/loading';
import {
  AddSalesDataForm,
  Compare,
  Cost,
  Header,
  IngredientResults,
  ProfitProjection,
  RecipeTemplate,
  Scale,
  GuestHeader,
  RecipeProfitGraph,
} from './sections';
import { TargetFoodMarginExceeded } from './sections/targetFoodMarginExceeded';
import { overlayConstants } from '../../shared/layout/layoutOverlay/constants';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useRecipeActiveTimeTracker } from '../../../utils/customHooks/useRecipeActiveTimeTracker';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

const RecipeResults: FC<RouteComponentProps> = ({ history }) => {
  const {
    selectedRecipe,
    selectedVenueObject,
    selectedRecipeVersion,
    newRecipe,
    recipeUpdated,
    overlayToggle,
    account,
    newRecipeFromTemplate,
    toolTip: { show },
    dispatch,
  } = useContext(LayoutContext);
  const [UpdateRecipeMutation, updateRecipe] = useUpdateRecipeMutation();
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const { getTotalRecipeActiveSeconds } = useRecipeActiveTimeTracker();

  const { push } = useHistory();
  const user = useMeQuery({ fetchPolicy: 'cache-only' });

  const venue = useVenueQuery({
    variables: {
      input: { venueId: selectedVenueObject?.id! },
    },
  });

  const recipe = useRecipeQuery({
    variables: {
      input: {
        venueId: selectedVenueObject?.id!,
        recipeId: selectedRecipe!,
      },
    },
  });

  const recipeResult = recipe?.data?.recipe?.recipe || undefined;
  const calculatorAddOn = account?.addOns.find(
    (a) => a.app === AddOnApp.Calculator
  );

  if (
    !selectedRecipe &&
    !newRecipeFromTemplate &&
    !recipe.data?.recipe.successful
  ) {
    push('/recipes');
  }

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      displayName: recipe?.data?.recipe.recipe?.displayName,
      salesPricePerServe:
        recipe.data?.recipe.recipe?.salesPricePerServe &&
        currency(recipe.data?.recipe.recipe?.salesPricePerServe),
      weeklySalesPerServe: recipe?.data?.recipe.recipe?.weeklySalesPerServe,
    },
  });

  useEffect(() => {
    const displayName = methods.watch('displayName');
    const salesPricePerServe = methods.watch('salesPricePerServe');
    const weeklySalesPerServe = methods.watch('weeklySalesPerServe');
    if (
      recipe.data?.recipe.recipe &&
      !displayName &&
      !salesPricePerServe &&
      !weeklySalesPerServe
    ) {
      const {
        displayName,
        salesPricePerServe,
        weeklySalesPerServe,
      } = recipe.data.recipe.recipe;
      methods.setValue('displayName', displayName);
      methods.setValue('salesPricePerServe', salesPricePerServe);
      methods.setValue('weeklySalesPerServe', weeklySalesPerServe);
    }
  }, [recipe, methods]);

  useEffect(() => {
    if (!recipe.loading) {
      const selectedVersion = recipe?.data?.recipe?.recipe?.recipeVersions.find(
        (version) =>
          selectedRecipeVersion
            ? version.id === selectedRecipeVersion
            : version.selected
      );

      if (selectedVersion && selectedVersion.id !== selectedRecipeVersion) {
        dispatch({
          type: 'SELECT_RECIPE_VERSION',
          payload: selectedVersion.id,
        });
      }

      if (
        recipe.data &&
        selectedVersion &&
        selectedVersion.id !== selectedRecipeVersion
      ) {
        methods.reset({
          displayName: recipe.data?.recipe.recipe?.displayName,
          salesPricePerServe:
            recipe.data?.recipe.recipe?.salesPricePerServe &&
            currency(recipe.data?.recipe.recipe?.salesPricePerServe),
          weeklySalesPerServe: recipe?.data?.recipe.recipe?.weeklySalesPerServe,
        });
      }

      if (recipe.data && recipeUpdated) {
        methods.reset({
          displayName: recipe.data?.recipe.recipe?.displayName,
          salesPricePerServe:
            recipe.data?.recipe.recipe?.salesPricePerServe &&
            currency(recipe.data?.recipe.recipe?.salesPricePerServe),
          weeklySalesPerServe: recipe?.data?.recipe.recipe?.weeklySalesPerServe,
        });
        dispatch({ type: 'TOGGLE_RECIPE_UPDATED' });
      }
    }
  }, [
    recipe,
    methods,
    selectedRecipeVersion,
    recipeUpdated,
    methods.reset,
    dispatch,
  ]);

  const updatedSalesPricePerServe = methods.watch('salesPricePerServe')
    ? Number(methods.watch('salesPricePerServe'))
    : newRecipe
    ? recipeResult?.salesPricePerServe || 0
    : 0;

  const updatedWeeklySalesPerServe = methods.watch('weeklySalesPerServe')
    ? Number(methods.watch('weeklySalesPerServe'))
    : newRecipe
    ? recipeResult?.weeklySalesPerServe || 0
    : 0;

  const recipeDataArgs = {
    recipe: recipeResult
      ? {
          ...recipeResult,
          salesPricePerServe: updatedSalesPricePerServe,
          weeklySalesPerServe: updatedWeeklySalesPerServe,
          recipeVersions: recipeResult.recipeVersions.map((version) => ({
            ...version,
            selected: version.id === selectedRecipeVersion,
          })),
        }
      : undefined,
    venue: venue.data?.venue?.userVenue?.venue || undefined,
  };

  const recipeData = getRecipeData(recipeDataArgs);

  const onSubmit = async (formData) => {
    if (
      checkIfAccountIsNotComplete(user.data?.me?.user?.email, account?.type)
    ) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: overlayConstants.noAccountSaveRecipe,
      });
      return;
    }
    if (
      !isEmpty(selectedRecipe) &&
      !isEmpty(selectedVenueObject?.id) &&
      recipeData
    ) {
      try {
        await UpdateRecipeMutation({
          variables: {
            input: {
              id: selectedRecipe!,
              venueId: selectedVenueObject?.id!,
              displayName: formData.displayName,
              salesPricePerServe: Number(formData.salesPricePerServe),
              weeklySalesPerServe: Number(formData.weeklySalesPerServe),
              recipeRevenue: recipeData.recipeRevenue,
              recipeProfit: recipeData.recipeProfit,
              originalRecipeProfit: recipeData.originalRecipeProfit,
              originalRecipeServes: recipeData.originalRecipeServes,
              recipeProfitIncreasePerServe: recipeData.profitIncreasePerServe,
              highestRecipeProfitIncreasePerServe:
                recipeData.highestProfitIncreasePerServe,
              foodCostPercentage: recipeData?.foodCostPercentage || 0,
              ingredientCost: recipeData?.foodCost,
              staffCost: recipeData?.totalStaffTimeCost,
              totalCost: recipeData?.recipeCost,
              serves: recipeData.serves,
              totalGrams: recipeData.totalGrams,
              totalActiveSeconds: getTotalActiveSeconds(),
              recipeActiveSeconds: getTotalRecipeActiveSeconds(),
            },
          },
          refetchQueries: [
            {
              query: RecipeDocument,
              variables: {
                input: {
                  venueId: selectedVenueObject?.id!,
                  recipeId: selectedRecipe!,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
        });
      } catch (err) {
        console.log('err ###', err);
      }
    }
  };

  const handleAddRecipe = () => {
    dispatch({ type: 'SELECT_RECIPE', payload: '' });
    dispatch({ type: 'SELECT_RECIPE_VERSION', payload: '' });
    history.push('/add-recipe');
  };

  const updateOriginalRecipeFromTemplate = () => {
    if (recipe.data?.recipe.recipe?.originalRecipeProfit === 0) {
      // Recipe Template Recipe needs to save original recipe profit before it is overwritten in the duplicate procress
      const formData = methods.getValues();
      onSubmit(formData);
    }
  };

  const updateRecipeOnSelect = (recipeVersionId: string) => {
    const updatedRecipeData = getRecipeData({
      recipe: recipeResult
        ? {
            ...recipeResult,
            salesPricePerServe: Number(
              methods.watch('salesPricePerServe') || 0
            ),
            weeklySalesPerServe: Number(
              methods.watch('weeklySalesPerServe') || 0
            ),
            recipeVersions: recipeResult.recipeVersions.map((version) => ({
              ...version,
              selected: version.id === recipeVersionId,
            })),
          }
        : undefined,
      venue: venue.data?.venue?.userVenue?.venue || undefined,
    });

    if (!updatedRecipeData) return;

    try {
      UpdateRecipeMutation({
        variables: {
          input: {
            id: updatedRecipeData.recipeId!,
            venueId: selectedVenueObject?.id!,
            displayName: updatedRecipeData.recipeName,
            salesPricePerServe: updatedRecipeData.salesPricePerServe,
            recipeRevenue: updatedRecipeData.recipeRevenue,
            recipeProfit: updatedRecipeData.recipeProfit,
            recipeProfitIncreasePerServe:
              updatedRecipeData.profitIncreasePerServe,
            highestRecipeProfitIncreasePerServe:
              updatedRecipeData.highestProfitIncreasePerServe,
            foodCostPercentage: updatedRecipeData.foodCostPercentage,
            recipeIngredientIdArray:
              updatedRecipeData?.recipeIngredientIds || [],
          },
        },
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  if (recipe.loading || !recipe.data || !recipeData) {
    return <LogoLoading size={60} />;
  }

  if (newRecipe && !overlayToggle && recipeData && recipe.data) {
    dispatch({
      type: 'SET_OVERLAY',
      payload: {
        ...overlayConstants.addSalesDataRecipeResults,
        subHeading: `To calculate recipe revenue for ${recipeData.recipeName}`,
        link: account?.type === AccountType.Guest ? '/' : '/recipes',
        content: (
          <AddSalesDataForm
            recipeDataArgs={recipeDataArgs}
            recommendedPrice={recipeData.recommendedPrice}
            getTotalRecipeActiveSeconds={getTotalRecipeActiveSeconds}
          />
        ),
      },
    });
  }

  const navButtons =
    account?.type === AccountType.Guest
      ? [
          <Button
            color="secondary"
            onClick={methods.handleSubmit(onSubmit)}
            disabled={updateRecipe.loading}
          >
            <DotsLoading
              text={(loading) => `${loading ? 'Saving' : 'Save'} Recipe`}
              isLoading={updateRecipe.loading}
              size="small"
              lineHeight={10}
              noMargin
            />
          </Button>,
          <Button color="primary" onClick={() => history.push('sign-up')}>
            Create Account
          </Button>,
        ]
      : [
          <Button inversed color="default" onClick={handleAddRecipe}>
            Add Recipe
          </Button>,
          <Button
            color="secondary"
            onClick={methods.handleSubmit(onSubmit)}
            disabled={updateRecipe.loading}
          >
            <DotsLoading
              text={(loading) => `${loading ? 'Saving' : 'Save'} Recipe`}
              isLoading={updateRecipe.loading}
              size="small"
              lineHeight={10}
              noMargin
            />
          </Button>,
        ];

  return (
    <FormProvider {...methods}>
      <LayoutPage
        align={show ? 'left' : 'center'}
        withLine
        heading="Recipe Results"
        subHeading="Tinker with results to fine increase your profit"
        actionArray={navButtons}
      >
        <form autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
          <GuestHeader />
          <Header recipeData={recipeData} />
          {user?.data?.me?.user?.accountType === AccountType.RecipeRevenue && (
            <RecipeTemplate recipeData={recipeData} />
          )}
          {(calculatorAddOn ||
            account?.type === AccountType.BusinessStarter ||
            account?.type === AccountType.RecipeRevenue) && (
            <TargetFoodMarginExceeded recipeData={recipeData} venue={venue} />
          )}
          <Scale
            recipeData={recipeData}
            updateOriginalRecipeFromTemplate={updateOriginalRecipeFromTemplate}
          />
          <IngredientResults recipe={recipe} venue={venue} />
          <Compare
            data={recipe}
            venue={venue}
            updateRecipeOnSelect={updateRecipeOnSelect}
            updateOriginalRecipeFromTemplate={updateOriginalRecipeFromTemplate}
          />
          <ProfitProjection recipeData={recipeData} />
          <RecipeProfitGraph
            data={recipe}
            venue={venue}
            recipeData={recipeData}
          />
          <Cost recipeData={recipeData} venue={venue} />
        </form>
      </LayoutPage>
    </FormProvider>
  );
};

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required('Recipe name is required'),
  salesPricePerServe: Yup.string()
    .required('Price required')
    .typeError('Price required')
    .test('numberTest', 'Not a number', (value) => validateNumberValues(value))
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),

  weeklySalesPerServe: Yup.string()
    .required('Required')
    .typeError('Required')
    .test('numberTest', 'Not a number', (value) => validateNumberValues(value))
    .test('positiveNumberTest', 'Number must be greater than zero', (value) =>
      validatePositiveNumberValues(value)
    ),
});

export default memo(RecipeResults);
