import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Container,
  Content,
  Left,
  Main,
  RegisterButton,
  Right,
  Box,
  AddressForm,
  PageHeading,
  Heading,
} from './Payment.styles';
import * as Yup from 'yup';
import { RouteComponentProps } from 'react-router-dom';
import { LayoutContext } from '../../../contexts/layoutContext';
import { H3 } from '../../shared/typefaces/Typefaces.styles';
import { Alert, AngleBox } from '../../shared/layout';
import { ProgressBar } from '../../shared/onboardingProgressBar';
import { CardField } from '../../shared/formElements/cardField';
import { FormProvider, useForm } from 'react-hook-form';
import { DotsLoading } from '../../shared/loading';
import { useBilling } from '../../../utils/customHooks/useBilling';
import { useUpdateSubscriptionMutation } from '../../../generated/graphql';
import { SelectedPlan } from '../../shared/layout/plans';
import { plansArray } from '../../../utils/helper/strings';
import { Toast } from '../../shared/toast';
import Address, {
  IAddressFormProps,
} from '../../shared/formElements/address/Address';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddress } from '../../../utils/customHooks/useAddress';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

const PaymentForm: FC<RouteComponentProps> = ({ history }) => {
  const {
    isSaving,
    errorMessage,
    setErrorMessage,
    savePaymentMethod,
  } = useBilling();
  const { getAddressErrors } = useAddress();
  const [savingUser, setSavingUser] = useState(false);
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const {
    newAccount,
    account,
    selectedVenueObject,
    selectedRecipe,
    selectedRecipeVersion,
    dispatch,
  } = useContext(LayoutContext);
  const [updateSubscriptionMutation] = useUpdateSubscriptionMutation();

  useEffect(() => {
    dispatch({ type: 'CLOSE_MENU' });
  }, [dispatch]);

  const selectedPlan = plansArray.find((sp) => {
    return sp.type === newAccount?.type;
  });

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (formData: IAddressFormProps) => {
    const totalActiveSeconds = getTotalActiveSeconds();
    const result = await savePaymentMethod(formData, totalActiveSeconds);
    if (result?.success) {
      setSavingUser(true);
      if (newAccount && account && newAccount.type !== account.type) {
        try {
          const addOnsFormatted = newAccount.addOns
            ?.filter((a) => a.updateAddOn)
            .map((a) => {
              return a.app;
            });

          const result = await updateSubscriptionMutation({
            variables: {
              input: {
                accountType: newAccount.type,
                billingCycle: newAccount.billingCycle,
                addOns: addOnsFormatted,
                venueId: selectedVenueObject?.id!,
                totalActiveSeconds,
              },
            },
            refetchQueries: ['SubscriptionData'],
            awaitRefetchQueries: true,
          });

          if (result.data?.updateSubscription.successful) {
            const u = result.data?.updateSubscription.user;
            dispatch({
              type: 'SET_ACCOUNT',
              payload: {
                type: u?.accountType,
                billingCycle: u?.billingCycle,
                addOns: u?.addOns,
              },
            });
            setSavingUser(false);
            if (selectedRecipe && selectedRecipeVersion) {
              history.push('/recipe-results');
            } else {
              history.push('/recipes');
            }
          } else {
            console.log('Update User', result.data?.updateSubscription.error);
            setErrorMessage(result.data?.updateSubscription.error);
          }
        } catch (error) {
          console.log('error', error);
          setSavingUser(false);
        }
      } else {
        setErrorMessage(result.response?.data?.savePaymentMethod.error);
        console.log('ERROR: ', result);
        history.push('/recipes');
      }
    }
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <AngleBox color="primary" marginTop="large" />
        <Main>
          <ProgressBar currentStage="personalDetails" />
          <Content>
            <Left>
              <div>
                <PageHeading>Billing Details</PageHeading>
              </div>
            </Left>
            <Right>
              <Box>
                <AngleBox color="secondary" marginTop="small" height="tiny" />
                <H3 className="heading">Add Billing Details</H3>
                <div className="content">
                  {errorMessage && (
                    <Toast
                      type="error"
                      heading="Billing Error"
                      className="errorToast"
                      message={
                        errorMessage || 'Please contact the billing team'
                      }
                      addMargin
                    />
                  )}
                  <AddressForm>
                    <Heading
                      color="grey"
                      fontWeight="semibold"
                      hideMarginBottom
                    >
                      Billing Address
                    </Heading>
                    <Address
                      label="Billing Address"
                      control={methods.control}
                      errors={getAddressErrors(methods.errors)}
                      getValues={methods.getValues}
                      reset={methods.reset}
                    />
                  </AddressForm>
                  <Heading color="grey" fontWeight="semibold">
                    Card Details
                  </Heading>
                  <Alert
                    icon="valid"
                    content="The card details entered will be used for RecipeRevenue.com purchases"
                    color="secondary"
                  />
                  <CardField />
                  <RegisterButton
                    color="secondary"
                    onClick={methods.handleSubmit(onSubmit)}
                    disabled={isSaving}
                  >
                    <DotsLoading
                      text={(loading) =>
                        savingUser
                          ? 'Loading Account'
                          : `${loading ? 'Saving' : 'Save'} Billing Details`
                      }
                      isLoading={isSaving || savingUser}
                      size="small"
                      lineHeight={10}
                      noMargin
                    />
                  </RegisterButton>
                </div>
              </Box>
            </Right>
          </Content>
          {selectedPlan && (
            <Content>
              <SelectedPlan {...selectedPlan} />
            </Content>
          )}
        </Main>
      </FormProvider>
    </Container>
  );
};

const validationSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Address Line 1 is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required'),
  postcode: Yup.string().required('Postcode is required'),
});

export default PaymentForm;
