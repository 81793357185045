import React, { FC, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import logo from '../../../../../images/logo100-min.png';
import { useAccessToken } from '../../../../../utils/accessToken';
import { Question } from '../../../../shared/icons';
import { H2 } from '../../../../shared/typefaces/Typefaces.styles';
import {
  AuthLink,
  Chat,
  Container,
  Logo,
  Menu,
  MenuItem,
  MenuLink,
  menuProps,
  Section,
  SideMenu,
} from './Header.styles';
import { IOverlayProps } from '../../../../shared/layout/layoutOverlay/layoutOverlay.types';
import { overlayConstants } from '../../../../shared/layout/layoutOverlay/constants';
import { useChatbox } from '../../../../../utils/customHooks/useChatbox';

interface OptionsProps extends menuProps {
  options: ItemProps[];
}
interface ItemProps {
  text: string;
  link: string;
  highlight?: boolean;
  mobileHide?: boolean;
  authLink?: string;
}

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { handleShowChatbox } = useChatbox();

  const {
    help: { showHelpModal },
    dispatch,
  } = useContext(LayoutContext);
  const leftOptions: ItemProps[] = [
    {
      text: 'Recipe Revenue',
      link: '',
    },
    {
      text: 'Calculator',
      link: 'select-account',
      authLink: 'recipes',
    },
    {
      text: 'Recipes',
      link: '#pre-made-recipes',
    },
    {
      text: 'Pricing',
      link: 'pricing',
    },
  ];

  const rightOptions: ItemProps[] = [
    {
      text: 'Log in',
      link: 'sign-in',
      mobileHide: true,
    },
    {
      text: 'Sign up',
      link: 'select-account',
      highlight: true,
    },
  ];

  const handleHelpClick = () => {
    dispatch({
      type: 'TOGGLE_HELP_MODAL',
    });

    if (!showHelpModal) {
      dispatch({
        type: 'SET_OVERLAY',
        payload: {
          ...overlayConstants.helpModal,
          buttonCTA: () => {
            handleShowChatbox();
          },
          linkCTA: () => {
            dispatch({
              type: 'TOGGLE_HELP_MODAL',
            });
            dispatch({ type: 'CLOSE_OVERLAY' });
            dispatch({ type: 'RESET_ALL_TOOL_TIPS' });
          },
        } as IOverlayProps,
      });
    }

    setShowMenu(false);
  };

  return (
    <>
      <Container>
        <Section>
          <Logo src={logo} alt="Recipe Revenue Logo" />
          <Options options={leftOptions} />
        </Section>
        <Section>
          <Chat onClick={handleHelpClick}>
            <Question size="small" className="question" />
          </Chat>
          <Options options={rightOptions} showAll />
          {/* <div className="menuIcon" onClick={() => setShowMenu(!showMenu)}>
            <MenuIcon size="medium" />
          </div> */}
        </Section>
      </Container>
      {showMenu && (
        <SideMenu>
          <Section className="menuHeader">
            <Logo src={logo} alt="Recipe Revenue Logo" />
            <H2 className="menuHeading">Recipe Revenue</H2>
          </Section>
          <Options
            options={[...leftOptions.slice(1), rightOptions[0]]}
            vertical
          />
        </SideMenu>
      )}
    </>
  );
};

const Options: FC<OptionsProps> = ({ options, vertical, showAll }) => {
  const [accessToken, isLoading] = useAccessToken();
  const { pathname } = useLocation();
  const { push } = useHistory();
  return (
    <Menu vertical={vertical} showAll={showAll}>
      {options.map(({ text, link, highlight, mobileHide, authLink }) => (
        <MenuItem key={link} mobileHide={mobileHide}>
          {accessToken && authLink && !isLoading ? (
            <AuthLink
              onClick={() => push(`/${authLink}`)}
              highlight={highlight}
              selected={pathname === authLink}
            >
              {text}
            </AuthLink>
          ) : (
            <MenuLink
              href={`/${link}`}
              highlight={highlight}
              selected={pathname === link}
            >
              {text}
            </MenuLink>
          )}
        </MenuItem>
      ))}
    </Menu>
  );
};
